import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducers'
import createSagaMiddleware from 'redux-saga'

const initialiseSagaMiddleware = createSagaMiddleware()

const storeEnhancers = window.REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose

export const store = createStore(rootReducer, storeEnhancers(applyMiddleware(initialiseSagaMiddleware)))

