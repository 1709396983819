import React, { useState } from 'react';
import { BiCalendar } from "react-icons/bi";

const Step1=(props)=>{

    const [data, setData] = useState({
        fondo: "",
        valor: "",
        cuotapartista: "",
		agente: "",
	});

    const handleChange = (e) => {
		setData({
		  ...data,
		  [e.target.name]: e.target.value,
		});
	};
    const applyNextStep = () => {
		
			props.goToStep(2);

	}

    return (   
        <div>     
            <div className='box-documento'>
                <div className='box-dots'>
                    <div className='dot dot-active'></div>
                    <hr className='hr-dot'/>
                    <div className='dot'></div>
                    <hr className='hr-dot'/>
                    <div className='dot'></div>
                </div>
                <div className='boxN title-doc'>
                    <h3>Rescates</h3>
                </div>
                <div className='boxN'>
                    <p className='subtitle-doc'>Número de solicitud <span>1000002</span></p>
                </div>
                <div className='boxN display-cont'>
                    <div className='fechas-doc'>
                        <p className='subtitle-doc'>Fecha de concertación</p>
                        <p className='fecha-doc'><BiCalendar/> 29/10/2022</p>
                    </div>
                    <div className='fechas-doc'>
                        <p className='subtitle-doc'>Fecha de liquidación</p>
                        <p className='fecha-doc'><BiCalendar/> 31/10/2022</p>
                    </div>
                </div>
                <div className="input-doc">
                    <label>Fondo</label>
                    <input  type="text" name="fondo" defaultValue={data.fondo} onChange={handleChange}></input> 
                </div>
                <div className="input-doc">
                    <label>Tipo de valor cuotaparte</label>
                    <input type="text" name="valor" defaultValue={data.valor} onChange={handleChange}></input>
                </div>
                <div className="input-doc">
                    <label>Cuotapartista</label>
                    <input type="text" name="cuotapartista" defaultValue={data.cuotapartista} onChange={handleChange}></input>
                </div>
                <div className="input-doc">
                    <label>Agente colocador</label>
                    <input type="text" name="agente" defaultValue={data.agente} onChange={handleChange}></input>
                </div>
                <div className="botonera-doc">
                    <button className="btnLineGrey">Cancelar</button>
                    <button className="btnColorGrey"onClick={() => applyNextStep()}>Siguiente</button>
                </div>
            </div>
        </div>      
    )
}

export default Step1 ;