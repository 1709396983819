import React, { useEffect, useState } from 'react';
import './widget.css'
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineExpand, AiOutlineCompress, AiOutlineClose } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import {AngleUpIcon, AngleDownIcon} from 'react-line-awesome'
import axios from 'axios';
import urlFunds from '../../utilities/urlFunds';
  
export default function WidgetCartera(props) {
  const [isNormal, setNormal] = useState("false");
  const [estado, setEstado] = useState(null);

  const dispatch = useDispatch();

  const cerrarExtension = () => {
            
    setEstado(null);

  };

  const EliminarWidget = (id) => {
    dispatch({
      type: "modal-eliminar-widget",
      payload: {
        estado: "abierto",
        idWidget: id,
      },
    });}
    const FiltroModal = (id) => {
      dispatch({
        type: "abrir-modal-filtroModal",
        payload: {
          estado: "abierto",
          idWidget: id,
        },
      });}
  const [sortedDate, setSortedDate] = useState({
      property: "carteraDate",
      ascending: "",
  });

  const [sortedFund, setSortedFund] = useState({
      property: "name",
      ascending: "",
  });

  const [sortedAmount, setSortedAmount] = useState({
      property: "cartera",
      ascending: "",
  });

  const filtroGeneral = useSelector((state) => state.FiltroGeneralReducer);
  const [funds, setFunds] = useState([]);

  const sortFund = () => {
      let value = "";
      if(sortedFund.ascending === ""){
         value = "true";
      } else if (sortedFund.ascending === "true"){
          value = "false";
      } 

      setSortedFund({...sortedFund,
          ascending: value
      });
  };

  useEffect(() => {
    let json = {
      page: 0,
      size: 20,
      sorting: [
        sortedFund,
        sortedAmount,
        sortedDate,
      ],
    };

    if(filtroGeneral.filtros !== null && filtroGeneral.filtros !== undefined && Array.isArray(filtroGeneral.filtros)){
      json = {...json,
        filters: filtroGeneral.filtros
      }
    }
    
    axios.post(urlFunds + "/api/funds/list-carteras", JSON.stringify(json), {
        headers: {
          "content-type": "application/json",
        },
      }).then((response) => {
          setFunds(response.data.results);
      });
    
    }, [sortedFund, sortedAmount, sortedDate, filtroGeneral])

  const handleToggle = () => {
    setNormal(!isNormal);
    setEstado("open");
  };

  const sortAmount = () => {
      let value = "";
      if(sortedAmount.ascending === ""){
         value = "true";
      } else if (sortedAmount.ascending === "true"){
          value = "false";
      } 

      setSortedAmount({...sortedAmount,
          ascending: value
      });
  };

  const sortDate = () => {
      let value = "";
      if(sortedDate.ascending === ""){
         value = "true";
      } else if (sortedDate.ascending === "true"){
          value = "false";
      } 

      setSortedDate({...sortedDate,
          ascending: value
      });
  };

  const handleClick = event => {
    event.currentTarget.classList.toggle('desplegableCarteraActive');
  };

    return (
        <div className= "widget">
          <div className='widgetHeader'>
              <div className='widgetHeaderLeft'>
                  <span className='widgetTitle'>Cartera Valorizada</span>
              </div>
              <div className='widgetHeaderRight'>
                <p title="Filtrar Elementos" className="linkFiltroModal" onClick={FiltroModal}>
                  <FaFilter component="span" className="widgetToolbar" />     
                </p>
                <p className={!isNormal ? "d-none" : "d-block"}  title="Expandir Elemento" onClick={handleToggle}>
                  <AiOutlineExpand component="span" className="widgetToolbar" />    
                </p> 
                <p className={isNormal ? "d-none" : "d-block"} title="Contraer Elemento" onClick={handleToggle}>
                  <AiOutlineCompress component="span" className="widgetToolbar" />    
                </p> 
                <p title="Cerrar Elemento"onClick={() =>EliminarWidget(props.position.id)}>
                  <AiOutlineClose component="span" className="widgetToolbar" />    
                </p> 
              </div>
          </div>
          <div className='widgetTableContainer'>
            <table className='widgetTable'>
                <tr className='widgetTr paddR20'>
                    <th className='widgetTh' style={{width : '20%', textAlign : 'left'}}>
                        <p onClick={sortFund} className="sortColumn">TP Inversión</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh' style={{width : '20%'}}>
                        <p onClick={sortAmount} className="sortColumn">Especie</p>
                        <AngleUpIcon component="span" className={sortedAmount.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedAmount.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh' style={{width : '20%'}}>
                        <p onClick={sortDate} className="sortColumn">Cantidad</p>
                        <AngleUpIcon component="span" className={sortedDate.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedDate.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh' style={{width : '20%'}}>
                        <p onClick={sortDate} className="sortColumn">Inversión</p>
                        <AngleUpIcon component="span" className={sortedDate.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedDate.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh' style={{width : '20%'}}>
                        <p onClick={sortDate} className="sortColumn">Valuado</p>
                        <AngleUpIcon component="span" className={sortedDate.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedDate.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                </tr>
                <div className="tablaCartera">
                  <div className="desplegableTablaCartera" onClick={handleClick}>
                    <div className="tipoDTC">
                      <div className="nombreTipoDTC"><p>Bono</p></div>
                      <div className="valorTipoDTC"><p>1300</p></div>
                    </div>
                    <div className="contenidoDTC">
                      <table>
                        <tr>
                          <td style={{width : '20%'}}></td>
                          <td style={{width : '20%'}}>+ Bono A</td>
                          <td style={{width : '20%'}}>100</td>
                          <td style={{width : '20%'}}>1</td>
                          <td style={{width : '20%'}}></td>
                        </tr>
                        <tr>
                          <td style={{width : '20%'}}></td>
                          <td style={{width : '20%'}}>+ Bono A</td>
                          <td style={{width : '20%'}}>100</td>
                          <td style={{width : '20%'}}>1</td>
                          <td style={{width : '20%'}}></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="desplegableTablaCartera" onClick={handleClick}>
                    <div className="tipoDTC">
                      <div className="nombreTipoDTC"><p>Bono</p></div>
                      <div className="valorTipoDTC"><p>1300</p></div>
                    </div>
                    <div className="contenidoDTC">
                      <table>
                        <tr>
                          <td style={{width : '20%'}}></td>
                          <td style={{width : '20%'}}>+ Bono A</td>
                          <td style={{width : '20%'}}>100</td>
                          <td style={{width : '20%'}}>1</td>
                          <td style={{width : '20%'}}></td>
                        </tr>
                        <tr>
                          <td style={{width : '20%'}}></td>
                          <td style={{width : '20%'}}>+ Bono A</td>
                          <td style={{width : '20%'}}>100</td>
                          <td style={{width : '20%'}}>1</td>
                          <td style={{width : '20%'}}></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

            </table>
            </div>
          <div className='widgetFooter'>
              <span className="widgetFooterTotal">
                
              </span>
          </div>
          <div className={estado != null ? "widget fullscreen" : "d-none"}>
            <div className='widgetHeader'>
                <div className='widgetHeaderLeft'>
                    <span className='widgetTitle'>Cartera Valorizada</span>
                </div>
                <div className='widgetHeaderRight'>
                  <p title="Filtrar Elementos" className="linkFiltroModal" onClick={FiltroModal}>
                    <FaFilter component="span" className="widgetToolbar" />      
                  </p>
                  <p className="d-block" title="Contraer Elemento" onClick={cerrarExtension}>
                    <AiOutlineCompress component="span" className="widgetToolbar" />    
                  </p> 
                </div>
            </div>
            <div className='widgetTableContainer'>
              {/* EJEMPLO DE TABLA
                  <table className='widgetTable'>
                  <tr className='widgetTr paddR20'>
                      <th className='widgetTh' style={{width : '20%', textAlign : 'left'}}>
                          <a href="#" onClick={sortFund} className="sortColumn">TP Inversión</a>
                          <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                          <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                      </th>
                      <th className='widgetTh' style={{width : '20%'}}>
                          <a href="#" onClick={sortAmount} className="sortColumn">Especie</a>
                          <AngleUpIcon component="span" className={sortedAmount.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                          <AngleDownIcon component="span" className={sortedAmount.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                      </th>
                      <th className='widgetTh' style={{width : '20%'}}>
                          <a href="#" onClick={sortDate} className="sortColumn">Cantidad</a>
                          <AngleUpIcon component="span" className={sortedDate.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                          <AngleDownIcon component="span" className={sortedDate.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                      </th>
                      <th className='widgetTh' style={{width : '20%'}}>
                          <a href="#" onClick={sortDate} className="sortColumn">Inversión</a>
                          <AngleUpIcon component="span" className={sortedDate.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                          <AngleDownIcon component="span" className={sortedDate.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                      </th>
                      <th className='widgetTh' style={{width : '20%'}}>
                          <a href="#" onClick={sortDate} className="sortColumn">Valuado</a>
                          <AngleUpIcon component="span" className={sortedDate.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                          <AngleDownIcon component="span" className={sortedDate.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                      </th>
                  </tr>
                  <div className="tablaCartera">
                    <div className="desplegableTablaCartera" onClick={handleClick}>
                      <div className="tipoDTC">
                        <div className="nombreTipoDTC"><p>Bono</p></div>
                        <div className="valorTipoDTC"><p>1300</p></div>
                      </div>
                      <div className="contenidoDTC">
                        <table>
                          <tr>
                            <td style={{width : '20%'}}></td>
                            <td style={{width : '20%'}}>+ Bono A</td>
                            <td style={{width : '20%'}}>100</td>
                            <td style={{width : '20%'}}>1</td>
                            <td style={{width : '20%'}}></td>
                          </tr>
                          <tr>
                            <td style={{width : '20%'}}></td>
                            <td style={{width : '20%'}}>+ Bono A</td>
                            <td style={{width : '20%'}}>100</td>
                            <td style={{width : '20%'}}>1</td>
                            <td style={{width : '20%'}}></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="desplegableTablaCartera" onClick={handleClick}>
                      <div className="tipoDTC">
                        <div className="nombreTipoDTC"><p>Bono</p></div>
                        <div className="valorTipoDTC"><p>1300</p></div>
                      </div>
                      <div className="contenidoDTC">
                        <table>
                          <tr>
                            <td style={{width : '20%'}}></td>
                            <td style={{width : '20%'}}>+ Bono A</td>
                            <td style={{width : '20%'}}>100</td>
                            <td style={{width : '20%'}}>1</td>
                            <td style={{width : '20%'}}></td>
                          </tr>
                          <tr>
                            <td style={{width : '20%'}}></td>
                            <td style={{width : '20%'}}>+ Bono A</td>
                            <td style={{width : '20%'}}>100</td>
                            <td style={{width : '20%'}}>1</td>
                            <td style={{width : '20%'}}></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>

              </table>*/}

              <table className='widgetTable'>
                <thead>
                  <tr className='widgetTr' style={{width : '100%'}}>
                    <th className='widgetTh'>
                        <p className="sortColumn">Ticker instrumento</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Denominación del instrumento</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">ISIN</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'> 
                        <p className="sortColumn">CNV</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Cantidad de vn</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Moneda</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Sector</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>  
                        <p className="sortColumn">Riesgo</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Duración</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>  
                        <p className="sortColumn">Emisor</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Mercado</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>  
                        <p className="sortColumn">Último precio</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>  
                        <p className="sortColumn">Tenencia valorizada</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                  </tr>
                </thead>
                <tbody className={!isNormal ? "h-auto" : "h-200"}>
                  <div className="tablaCartera">
                  <div className="desplegableTablaCartera" onClick={handleClick}>
                    <div className="tipoDTC">
                      <div className="nombreTipoDTC"><p>Bono</p></div>
                      <div className="valorTipoDTC"><p>1300</p></div>
                    </div>
                    <div className="contenidoDTC">
                      <table>
                        <tr>
                          <td style={{width : '20%'}}></td>
                          <td style={{width : '20%'}}>+ Bono A</td>
                          <td style={{width : '20%'}}>100</td>
                          <td style={{width : '20%'}}>1</td>
                          <td style={{width : '20%'}}></td>
                        </tr>
                        <tr>
                          <td style={{width : '20%'}}></td>
                          <td style={{width : '20%'}}>+ Bono A</td>
                          <td style={{width : '20%'}}>100</td>
                          <td style={{width : '20%'}}>1</td>
                          <td style={{width : '20%'}}></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="desplegableTablaCartera" onClick={handleClick}>
                    <div className="tipoDTC">
                      <div className="nombreTipoDTC"><p>Bono</p></div>
                      <div className="valorTipoDTC"><p>1300</p></div>
                    </div>
                    <div className="contenidoDTC">
                      <table>
                        <tr>
                          <td style={{width : '20%'}}></td>
                          <td style={{width : '20%'}}>+ Bono A</td>
                          <td style={{width : '20%'}}>100</td>
                          <td style={{width : '20%'}}>1</td>
                          <td style={{width : '20%'}}></td>
                        </tr>
                        <tr>
                          <td style={{width : '20%'}}></td>
                          <td style={{width : '20%'}}>+ Bono A</td>
                          <td style={{width : '20%'}}>100</td>
                          <td style={{width : '20%'}}>1</td>
                          <td style={{width : '20%'}}></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </tbody>

              </table>
            </div>
            <div className='widgetFooter'>
                <span className="widgetFooterTotal">
                  
                </span>
            </div>
      </div>
      </div>
      
    )
}