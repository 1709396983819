import React, { useState } from "react";
import "./menu.css";
import { useDispatch, useSelector } from "react-redux";
import Cuenta from "../../assets/icons/Iconos-barra-lateral/user-grey.png";

const MenuUsuario = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.TokenReducer);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if( open===true){
      handleClose();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    dispatch({
      type: "modal-cerrarsesion",
      payload: "abierto",
    });
    handleClose();
  }

  return (
    <div className="posrel">
      <div
        id="basic-button"
        className="imgUser"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img src={Cuenta} alt="Cuenta"/>
      </div>
      { open === true && 
        <div className="menuUsuario" tabIndex="-1"> 
          <div className="menuWrapper">
            <div className="menuHeader">
              <div>
                <span className="title">Configuracion de usuario</span>
              </div>
              <div>
                <img src={Cuenta} alt="Cuenta" />
              </div>
            </div>
            <div className="menuContent">
              <div className="button">
                <button tabIndex="-1" onClick={logout}>Cerrar Sesion</button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default MenuUsuario;
