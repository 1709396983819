import { combineReducers } from "redux";
import DesancladoReducer from "./reducers/DesancladoReducer";
import AncladoReducer from "./reducers/AncladoReducer";
import WidgetReducer from "./reducers/WidgetReducer";
import FiltroGeneralReducer from "./reducers/FiltroGeneralReducer";
import CerrarSesionReducer from "./reducers/CerrarSesionReducer";
import EliminarWidgetReducer from "./reducers/EliminarWidgetReducer";
import WorkspaceReducer from "./reducers/WorkspaceReducer";
import EstadoWorkspaceReducer from "./reducers/EstadoWorkspaceReducer";
import ContenedorWorkspacesReducer from "./reducers/ContenedorWorkspacesReducer";
import LayoutReducer from "./reducers/LayoutReducer";
import ArrayWidgetsReducer from "./reducers/ArrayWidgetsReducer";
import EstadoModalReducer from "./reducers/EstadoModalReducer";
import FilterReducer from "./reducers/FilterReducer";
import NotifReducer from "./reducers/NotifReducer";
import FiltrosReducer from "./reducers/FiltrosReducer";
import ResultFilterReducer from "./reducers/ResultFilterReducer";
import SelectLayoutReducer from "./reducers/SelectLayoutReducer";
import TokenReducer from "./reducers/TokenReducer";
import UserReducer from "./reducers/UserReducer";
import WidgetsReducer from "./reducers/WidgetsReducer";
import ErrorLoginModalReducer from"./reducers/ErrorLoginModalReducer";
import FiltroModalReducer from"./reducers/FiltroModalReducer";
import BodyHomeReducer from"./reducers/BodyHomeReducer";

const rootReducer = combineReducers({
    LayoutReducer,
    WorkspaceReducer,
    ContenedorWorkspacesReducer,
    DesancladoReducer,
    FiltroModalReducer,
    AncladoReducer,
    WidgetReducer,
    FiltroGeneralReducer,
    EstadoWorkspaceReducer,
    CerrarSesionReducer,
    EliminarWidgetReducer,
    ArrayWidgetsReducer,
    EstadoModalReducer,
    FilterReducer,
    NotifReducer,
    FiltrosReducer,
    ResultFilterReducer,
    SelectLayoutReducer,
    TokenReducer,
    UserReducer,
    WidgetsReducer,
    ErrorLoginModalReducer,
    BodyHomeReducer,

  });

  export default rootReducer;