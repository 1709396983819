import React, { useEffect, useState } from "react";

import "../menu/menu.css";
import { CloseIcon } from 'react-line-awesome'
import { FiChevronDown } from 'react-icons/fi'

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import urlBack from "../../utilities/urlBack";

import CardEscritorio from "../card/CardEscritorio";
import MenuCreateWorkspace from "./MenuCreateWorkspace";

const MenuEscritorios = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [components, setComponents] = useState(0);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.TokenReducer);
  const [anchor, setAnchor] = useState(false);
  const selectLayout = useSelector((state) => state.selectLayout);
  const style = useSelector((state) => state.style);

  const createWorkspace = () => {
    const layout = {
      description: type,
      components: components,
    };

    axios
      .post(urlBack + "/api/layouts", JSON.stringify(layout), {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res) => {
        const idLayout = res.data._links.layout.href.substr(
          res.data._links.layout.href.lastIndexOf("/") + 1,
          res.data._links.layout.href.length + 1
        );

        const workspace = {
          name: name,
          description: description,
          idLayout: idLayout,
        };

        axios
          .post(
            urlBack +
              "/workspace/create?name=" +
              name +
              "&description=" +
              description +
              "&idLayout=" +
              idLayout +
              "&token=" +
              token.token +
              "&anchor=" + 
              anchor
          )
          .then((result) => {
            dispatch({
              type: "WORKSPACE",
              payload: {
                id: result.data.id,
                name: result.data.name,
                description: result.data.description,
                layout: result.data.layout.description,
                anchor: result.data.anchor,
              },
            });

            dispatch({
              type: "LAYOUT",
              payload: {
                id: idLayout,
                description: res.data.description,
                components: res.data.components,
              },
            });

            axios.get(urlBack + "/user/workspaces?token=" + token.token).then((res) => {
              setOpen(false)
              dispatch({
                type: "WORKSPACES",
                payload: res.data,
              });

              dispatch({
                type: "workspace-activo",
                payload: result.data.id,
              });
            });
          });
      });

    handleClose();  
  };

  const addName = (e) => {
    setName(e.target.value);
  };
  const addDescription = (e) => {
    setDescription(e.target.value);
  };
  function addType(typeOfLayout, cantComponents) {
    let layout;
    
    if(selectLayout.selectLayout !== ""){
          
      layout = document.getElementById(selectLayout.selectLayout);
      layout.className ="layoutListItem";
    }

      dispatch({
        type: "SELECTLAYOUT",
        payload: typeOfLayout,
      })
      
    layout = document.getElementById(typeOfLayout)
    layout.className = "layoutListItemSelect";
    setType(typeOfLayout); 
    setComponents(cantComponents);
  }

  const addAnchor = (e) => {
    setAnchor(e.target.checked);
  }

  const workspacesArray = useSelector((state) => state.workspaces);

  const [workspaces, setWorkspaces] = useState([]);
  const [anchors, setAnchors] = useState([]);

  useEffect(() => {
    axios.get(urlBack + "/user/workspaces?token=" + token.token).then((res) => {
      setWorkspaces(res.data);
      setAnchors(res.data.filter(w => w.anchor === true).slice(0,4));
    });
  }, [workspacesArray]);
  
  return (
    <div>
      <div 
        id="workspaceBtn" 
        onClick={handleOpen}>
          <FiChevronDown className="btnEscritorios" />
          </div>

          { open === true && 

          <div className="contMenuEscritorios"> 
            <div className="menuEscritorios"> 
              <div className="menuWrapper">
                <div className="menuHeader">
                  <div>
                    <span className="title">Escritorios</span>
                  </div>
                  <div>
                    <CloseIcon className="btnClose" onClick={handleClose}/>
                  </div>
                </div>
                <div className="menuContent">
                  <div className="scroll">
                    <ul className="cardList">
                      {Object.keys(workspaces).map((id) => {
                        const workspace = workspaces[id];
                          return (
                            <>
                            <li>
                                <CardEscritorio
                                  token={token.token}
                                  workspace={workspace}
                                  key={workspace.id}
                                  className="active"
                                />
                              </li>
                            </>
                          );
                      })}

                        {/* <li><CardEscritorio /></li> */}
                    </ul>
                  </div>
                  <div className="button">
                    <MenuCreateWorkspace className="btnCreateWorkspace" onClick={handleClose} cantWorkspaces={props.cantWorkspaces} />
                    {/* <button className="btnCreateWorkspace" tabIndex="-1" onClick={createWorkspace}>Agregar Nuevo Escritorio</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>  
        }
    </div>
  );
}
export default MenuEscritorios;