import React from "react";
import "../Modals/desanclado-modal.css";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon} from 'react-line-awesome'
import { FaTimes } from "react-icons/fa";

const ErrorLoginModal = (props) => {

  const dispatch = useDispatch();
  const errorLogin = useSelector((state) => state.ErrorLoginModalReducer);
 
 function cerrar() {
    dispatch({
      type: "modal-ErrorLogin",
      payload: "cerrado",
    });
  }

  const notif = useSelector( state => {
		return state.NotifReducer;
	});

  return (
    <div className={errorLogin.estado === "abierto" ? "desanclado-modal-overlay" : "d-none"}>
      <div className="modal-login desanclado-modal-background">
        <div className='desanclado-modal-container'>
            <div className="desanclado-modal-toolbar">
              <p className="close-button" onClick={cerrar}>
                  <CloseIcon component="span" />    
              </p>
            </div>
            <div className="red-icon-modal"><FaTimes /></div>
            <h2>{notif.title}</h2>
            <p className="desanclado-modal-content">{notif.text}</p>
        </div>
      </div>
    </div>
);
};
export default ErrorLoginModal;
