import React from "react";
import "../Modals/selector-widgets.css";

import { useDispatch, useSelector } from "react-redux";
import Fondos from '../../assets/icons/Iconos-onboarding/barras1.png';
import Informes from '../../assets/icons/Iconos-onboarding/barras5.png';
import Rescates from '../../assets/icons/Iconos-onboarding/barras3.png';
import Suscripción from '../../assets/icons/Iconos-onboarding/barras.png';
import Vcp from '../../assets/icons/Iconos-onboarding/barras2.png';
import Cartera from '../../assets/icons/Iconos-onboarding/barras4.png';

import axios from "axios";
import urlBack from "../../utilities/urlBack";

import { CloseIcon} from 'react-line-awesome'


const SelectorWidgets = () => {

  const dispatch = useDispatch();

  const reducer = useSelector((state) => state.WidgetReducer);
  
  const actualizarWidget = (type) => {
    const widget = {
      type: type,
      position: reducer.posicion,
    };

    axios
      .post(urlBack + "/api/widgets", JSON.stringify(widget), {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res) => {
        const id = res.data._links.widget.href.substr(res.data._links.widget.href.lastIndexOf("/") + 1, res.data._links.widget.href.length + 1);
        axios
          .post(urlBack + "/workspace/addWidget?idWorkspace=" + reducer.workspace + "&idWidget=" + id)
          .then((result) => {
            axios
              .get(urlBack + "/workspace/findWidgets?idWorkspace=" + reducer.workspace)
              .then((re) => {
                dispatch({
                  type: "WIDGETS",
                  payload: re.data, //no se guarda ningun tipo de id aca. 
                });
              });
          });
      });
  };

  function cerrar() {
    dispatch({
      type: "cancelar-widget",
      payload: "",
    });
  }

  const seleccionwidget = (valor) => {
    dispatch({
      type: "agregar-widget",
      payload: {
        widget: valor,
      }
    });

    actualizarWidget(valor);
  };

  return (
    <>
      {reducer.modal === "abierto" && 
        <div className="widget-modal-overlay">
          <div className="widget-modal-background">
            <div className="widget-modal-header">
                <div className="widget-modal-title">
                  <h2>Selección de Widget</h2>
                </div>
                <div className="widget-modal-toolbar">
                  <p className="close-button" onClick={cerrar}>
                      <CloseIcon component="span" />    
                  </p>
                </div>
            </div>

            <div className='widget-selector-container'>
              <div className='widget-selector-area'>
                  <span className='widget-selector' id="1" onClick={()=>seleccionwidget("1")}>
                    <img src={Fondos} className="imgSelector" alt="Selector"></img>
                  </span>
                  <p className="widget-selector-text">Widget Fondos<br/><small>Herramientas de gestión de fondos de inversión.</small></p>
              </div>
              <div className='widget-selector-area'>
                  <span className='widget-selector' id="5" onClick={()=>seleccionwidget("5")}>
                    <img src={Cartera} className="imgSelector" alt="Selector"></img>
                  </span>
                  <p className="widget-selector-text">Widget Cartera Valorizada<br/><small>Herramientas de gestión de carteras.</small></p>
              </div>
              <div className='widget-selector-area'>
                  <span className='widget-selector' id="2" onClick={()=>seleccionwidget("2")}>
                    <img src={Vcp} className="imgSelector" alt="Selector"></img>
                  </span>
                  <p className="widget-selector-text">Widget VCP<br/><small>Herramientas de gestión de cartera valorizada.</small></p>
              </div>
              <div className='widget-selector-area'>
                  <span className='widget-selector' id="3" onClick={()=>seleccionwidget("3")}>
                    <img src={Suscripción} className="imgSelector" alt="Selector"></img>
                  </span>
                  <p className="widget-selector-text">Widget de Total de Suscripciones<br/><small>Herramientas de gestión de suscripciones.</small></p>
              </div>
              <div className='widget-selector-area'>
                  <span className='widget-selector' id="6" onClick={()=>seleccionwidget("6")}>
                    <img src={Informes} className="imgSelector" alt="Selector"></img>
                  </span>
                  <p className="widget-selector-text">Widget de Detalle de Solicitud de Suscripción<br/><small>Herramientas de gestión de suscripciones.</small></p>
              </div>
              <div className='widget-selector-area'>
                  <span className='widget-selector' id="4" onClick={()=>seleccionwidget("4")}>
                    <img src={Rescates} className="imgSelector" alt="Selector"></img>
                  </span>
                  <p className="widget-selector-text">Widget de Total de Rescates<br/><small>Herramientas de gestión de rescates.</small></p>
              </div>
              <div className='widget-selector-area'>
                  <span className='widget-selector' id="4" onClick={()=>seleccionwidget("7")}>
                    <img src={Rescates} className="imgSelector" alt="Selector"></img>
                  </span>
                  <p className="widget-selector-text">Widget de Detalle de Solicitudes de Rescates<br/><small>Herramientas de gestión de rescates.</small></p>
              </div>
              <div className='widget-selector-area'>
                  <span className='widget-selector' id="5" onClick={()=>seleccionwidget("8")}>
                    <img src={Cartera} className="imgSelector" alt="Selector"></img>
                  </span>
                  <p className="widget-selector-text">Widget Liquidez<br/><small>Herramientas de gestión de Liquidez.</small></p>
              </div>
              <div className='widget-selector-area'>
                  <span className='widget-selector' id="6" onClick={()=>seleccionwidget("9")}>
                    <img src={Informes} className="imgSelector" alt="Selector"></img>
                  </span>
                  <p className="widget-selector-text">Widget Operaciones<br/><small>Herramientas de gestión de operaciones.</small></p>
              </div>

            </div>
          </div>
        </div>
      }
    </>
);
};
export default SelectorWidgets;
