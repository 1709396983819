import React from "react";
import "../Modals/desanclado-modal.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import urlBack from "../../utilities/urlBack";
import { AiOutlineExclamationCircle } from "react-icons/ai";

const AncladoModal = (props) => {


  const dispatch = useDispatch();

  const token = useSelector((state) => state.TokenReducer);
  const anclado = useSelector((state) => state.AncladoReducer);
 
 function cerrar() {
    dispatch({
      type: "cerrar-modal-anclado",
      payload: "",
    });
  }

  function anclar() {
    if(anclado.workspace != ''){
      axios.post(urlBack + "/workspace/addAnchor?id="+ anclado.workspace + "&token=" + token.token)
        .then((result) => {
          axios.get(urlBack + "/user/workspaces?token=" + token.token).then((res) => {
            dispatch({
              type: "WORKSPACES",
              payload: res.data,
            });
          });
        
        });
    }
    cerrar();
  }
  
  return (
        <div className={anclado.workspace != '' ? "desanclado-modal-overlay" : "d-none"}>
          <div className="desanclado-modal-background">
            <div className='desanclado-modal-container'>
              <div className="yellow-icon-modal"><AiOutlineExclamationCircle /></div>
              <p className="desanclado-modal-content">¿Desea anclar el espacio de trabajo?</p>
              <div className='desanclado-toolbar'>
                <button className="desanclado-button line-grey" onClick={cerrar}>
                  No
                </button>
                <button className="desanclado-button btn-grey" onClick={anclar}>
                  Si
                </button>
              </div>
            </div>
          </div>
        </div>
  );
};
export default AncladoModal;
