import React, { useEffect, useState } from "react";
import "../menu/menu.css";
import SearchIcon from "../../assets/icons/Iconos-barra-superior/lupa.png";
import StarIconFull from "../../assets/icons/estrella1.png";
import StarIcon from "../../assets/icons/estrella2.png";
import axios from "axios";
import urlFunds from "../../utilities/urlFunds";
import urlBack from "../../utilities/urlBack";
import { useDispatch, useSelector } from "react-redux";
import { BiFilter } from "react-icons/bi";
import { FaChevronDown } from "react-icons/fa";

const FiltroFondos = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [funds, setFunds] = useState([]);
  const [fundsFav, setFundsFav] = useState([]);

  const [filters, setFilters] = useState([]);

  const token = useSelector((state) => state.TokenReducer);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if( open===true){
      handleClose();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const json = {
      page: 0,
      size: 20,
      sorting: [
        {
          property: "name",
          ascending: "true",
        },
      ],
    };
    axios
      .post(urlFunds + "/api/funds/list-funds", JSON.stringify(json), {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res) => {
        setFunds(res.data.results);
      });

   axios.get(urlBack + "/user/funds?token=" + token.token).then((res) => {
     setFundsFav(res.data);
   });
  }, []);

  const selectFav = (fund) => {
    const img = document.getElementById(fund.id);
    const fav = img.src.substr(img.src.lastIndexOf("/", 21), img.src.length);
    if (fav === StarIcon) {
      axios
        .post(
          urlBack +
            "/fund/create?id=" +
            fund.id +
            "&code=" +
            fund.code +
            "&money=" +
            fund.money +
            "&name=" +
            fund.name +
            "&abbreviation=" +
            fund.abbreviation +
            "&number=" +
            fund.number +
            "&token=" +
            token.token
        )
        .then((res) => {
          axios.get(urlBack + "/user/funds?token=" + token.token).then((result) => {
            setFundsFav(result.data);
          });
        });

      img.src = StarIconFull;
    } else {
      axios.post(urlBack + "/user/removeFoundFav?token=" + token.token + "&idFund=" + fund.id)
      .then((res) => {
        const newList = fundsFav.filter((item) => item.id !== fund.id);
        setFundsFav(newList)
      })
      img.src = StarIcon;
    }
  };

  const search = (e) => {
    const json = {
      page: 0,
      size: 20,
      filters: [{
        name: e.target.value,
      }],
      sorting: [
        {
          property: "name",
          ascending: "true",
        },
      ],
    };

    axios
      .post(urlFunds + "/api/funds/list-funds", JSON.stringify(json), {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res) => {
        setFunds(res.data.results);
      });
  }

  const filter = (e) => {
    let check = e.target.checked;

    let arrayFilters = filters

    const attributes = {
      name: e.target.value
    }

    if(check){
      filters.push(attributes);
      arrayFilters.push(attributes)
    } else {
      const newList = filters.filter((item) => item.name !== attributes.name);
      setFilters(newList);
      arrayFilters = newList;
    }



    dispatch({
      type: "filtrar-fondos-seleccionados",
      payload: arrayFilters,
    })
/*
    axios
      .post(urlFunds + "/api/funds/list-funds", JSON.stringify(json), {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res) => {
        console.log("RESULT FILTER....");
        console.log(res);
        dispatch({
          type: "RESULTFILTER",
          payload: {  page: res.data.page,
          size: res.data.size,
          filters: res.data.results,
          count: res.data.count,},
        })
      });
*/
  }

  return (
    <div>
      <div
        className="buttonFiltroFondos"
        id="fondo-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <BiFilter component="span" className="iconNav" />
        <span className="titleFondos">Fondos</span>
        <FaChevronDown component="span" className="iconFilter" />
      </div>

      { open === true && 
        <div className="menuFondos">
          <div className="menuWrapper">
            <div className="menuHeader">
              <div>
                <span className="title">Selector de Fondos</span>
              </div>
            </div>
            <div className="menuContent">
              <div>
                <span className="small">
                  Seleccione los fondos sobre los que deben operar los widgets.
                </span>
                <form action="">
                  <div className="searchBar">
                    <input type="search" onChange={search} />
                    <img src={SearchIcon} alt="Search"/>
                  </div>
                </form>

                <div className="favorites-content">
                  <span className="favorites">Favoritos</span>
                  <div className="scroll">
                    <hr className="short" />
                    <ul className="favoriteList">
                      {Object.keys(fundsFav).map((id) => {
                        const fav = fundsFav[id];
                        return (
                          <li className="favoriteListItem">
                            <div>
                              <input type="checkbox" value={fav.name} name="" onClick={filter} />
                              <span>{fav.name}</span>
                            </div>
                            <div>
                              <img
                                id={fav.id}
                                onClick={() => selectFav(fav)}
                                src={StarIconFull}
                                alt="Selector"
                              />
                            </div>
                          </li>
                        );
                      })}
                    </ul>

                    <span className="favorites">Fondos</span>
                    <hr className="short" />
                    <ul className="fondosList">
                      {Object.keys(funds).map((id) => {
                        const fund = funds[id];
                        let selected = false;
                        fundsFav.map((fav) => { 
                          if(fav.code === fund.code){  
                                  selected = true;
                                   return;
                           }
                       });

                        console.log("-----------------------------------");
                        console.log(fund.code);

                        return (
                          <li className="fondosListItem">
                            <div>
                              <input type="checkbox" name="" value={fund.name} onClick={filter} />
                              <span>{fund.name}</span>
                            </div>
                            <div>
                              <img
                                id={fund.id}
                                src={selected ? StarIconFull : StarIcon}
                                onClick={() => selectFav(fund)}
                                alt="Selector"
                              />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    <button className="btnBorrarFiltros">Borrar Filtros</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     }
    </div>
  );
};

export default FiltroFondos;
