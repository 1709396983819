import React, { useEffect, useState } from 'react';
import './widget.css'
import { FaFilter } from "react-icons/fa";
import { AiOutlineExpand, AiOutlineCompress, AiOutlineClose } from "react-icons/ai";
import {  AngleUpIcon, AngleDownIcon} from 'react-line-awesome';
import axios from 'axios';
import urlFunds from '../../utilities/urlFunds';
import { useDispatch, useSelector } from 'react-redux';

export default function WidgetSuscripciones(props) {
  const [isNormal, setNormal] = useState("false");
  const [estado, setEstado] = useState(null);

  const dispatch = useDispatch();

  const cerrarExtension = () => {
    setEstado(null);
  };

  const EliminarWidget = (id) => {
    dispatch({
      type: "modal-eliminar-widget",
      payload: {
        estado: "abierto",
        idWidget: id,
      },
    });
}
const FiltroModal = (id) => {
  dispatch({
    type: "abrir-modal-filtroModal",
    payload: {
      estado: "abierto",
      idWidget: id,
    },
  });}
    
  const [sortedAmount, setSortedAmount] = useState({
      property: "subscribed",
      ascending: "",
  });

  const [sortedFund, setSortedFund] = useState({
      property: "name",
      ascending: "",
  });

  const [sortedMoney, setSortedMoney] = useState({
      property: "money",
      ascending: "",
  });

  const filtroGeneral = useSelector((state) => state.FiltroGeneralReducer);
  const [funds, setFunds] = useState([]);

  useEffect(() => {
      let json = {
        page: 0,
        size: 20,
        sorting: [
          sortedFund,
          sortedAmount,
          sortedMoney,
        ],
      };

      console.log(json);

      if(filtroGeneral.filtros !== null && filtroGeneral.filtros !== undefined && Array.isArray(filtroGeneral.filtros)){
        json = {...json,
          filters: filtroGeneral.filtros
        }
      }
      
      axios.post(urlFunds + "/api/funds/list-suscriptions", JSON.stringify(json), {
          headers: {
            "content-type": "application/json",
          },
        }).then((response) => {
            setFunds(response.data.results);
        });
      
  }, [sortedFund, sortedAmount, sortedMoney, filtroGeneral]);

  const handleToggle = () => {
    setNormal(!isNormal);
    setEstado("open");
  };

  const sortFund = () => {
      let value = "";
      if(sortedFund.ascending === ""){
        value = "true";
      } else if (sortedFund.ascending === "true"){
          value = "false";
      } 

      setSortedFund({...sortedFund,
          ascending: value
      });
  };

  const sortAmount = () => {
      let value = "";
      if(sortedAmount.ascending === ""){
        value = "true";
      } else if (sortedAmount.ascending === "true"){
          value = "false";
      } 

      setSortedAmount({...sortedAmount,
          ascending: value
      });
  };

  const sortMoney = () => {
      let value = "";
      if(sortedMoney.ascending === ""){
        value = "true";
      } else if (sortedMoney.ascending === "true"){
          value = "false";
      } 

      setSortedMoney({...sortedMoney,
          ascending: value
      });
  };

  return (
        <div className="widget">
          <div className='widgetHeader'>
              <div className='widgetHeaderLeft'>
                  <span className='widgetTitle'>Detalle Rescates</span>
              </div>
              <div className='widgetHeaderRight'>
                <p title="Filtrar Elementos" className="linkFiltroModal" onClick={FiltroModal}>
                  <FaFilter component="span" className="widgetToolbar" />     
                </p>
                <p className="d-block"  title="Expandir Elemento" onClick={handleToggle}>
                  <AiOutlineExpand component="span" className="widgetToolbar" />    
                </p> 
                <p title="Cerrar Elemento" onClick={() =>EliminarWidget(props.position.id)}>
                  <AiOutlineClose component="span" className="widgetToolbar" />    
                </p> 
              </div>
          </div>
          <div className='widgetTableContainer'>
            <table className='widgetTable'>
              <thead>
                <tr className='widgetTr' style={{width : '100%'}}>
                  <th className='widgetTh'>
                    <p><span className="sortColumn">Nombre</span>
                      <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                      <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </p>
                  </th>
                  <th className='widgetTh'> 
                    <p><span className="sortColumn">CNV</span>
                      <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                      <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </p>
                  </th>
                  <th className='widgetTh'>
                    <p><span className="sortColumn">Moneda</span>
                      <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                      <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody className={!isNormal ? "h-auto" : "h-200"}>
                  {funds.map((fund) => (
                      <tr className='widgetTrData' style={{width : '100%'}}>
                          <td className='widgetTd'></td>
                          <td className='widgetTd'></td>
                          <td className='widgetTd'></td>
                      </tr>
                  ))}
              
              </tbody>
            </table>
            </div>
          <div className='widgetFooter'>
              <span className="widgetFooterTotal">
                 
              </span>
          </div>
          
          <div className={estado != null ? "widget fullscreen" : "d-none"}>
            <div className='widgetHeader'>
              <div className='widgetHeaderLeft'>
                  <span className='widgetTitle'>Detalle Rescates</span>
              </div>
              <div className='widgetHeaderRight'>
                <p title="Filtrar Elementos" className="linkFiltroModal" onClick={FiltroModal}>
                  <FaFilter component="span" className="widgetToolbar" />      
                </p>
                <p className="d-block" title="Contraer Elemento" onClick={cerrarExtension}>
                  <AiOutlineCompress component="span" className="widgetToolbar" />    
                </p> 
              </div>
            </div>
            <div className='widgetTableContainer'>
              <table className='widgetTable'>
                <thead>
                  <tr className='widgetTr' style={{width : '100%'}}>
                    <th className='widgetTh'>
                        <p className="sortColumn">Abreviatura</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Nombre</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Clase</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'> 
                        <p className="sortColumn">CNV</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">CAFCI</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Moneda</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Cliente</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Fecha concertación</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Fecha liquidación</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Cantidad de cuotapartes rescatadas</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                        <p className="sortColumn">Importe</p>
                        <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                        <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                    </th>
                  </tr>
                </thead>
                <tbody className="h-auto" >
                  {funds.map((fund) => (
                    <tr className='widgetTrData' style={{width : '100%'}}>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='widgetFooter'>
                <span className="widgetFooterTotal">
                    
                </span>
            </div>
        </div>
      </div>  
    )
}