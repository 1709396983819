import React, { useState } from 'react';
import './documentos.css';
import Stepper from '../../utilities/stepper';
import Step1 from '../../components/StepsRescates/Step1';
import Step2 from '../../components/StepsRescates/Step2';
import Step3 from '../../components/StepsRescates/Step3';

const Rescates=(props)=>{

    const [Step, setStep] = useState(1);

	const [ formData, setFormData ] = useState({});
	const changeForm = (values) => {
		setFormData({...formData, ...values});
	}

	return(
		<div className='boxStep'>
			<Stepper setStep={setStep}>
				<Step1 setForm={changeForm} />
				<Step2 setForm={changeForm} />
				<Step3 setForm={changeForm} />		
			</Stepper>
		</div>
	)

    /*const [data, setData] = useState({
        fondo: "",
        valor: "",
        cuotapartista: "",
		agente: "",
		condicion: "",
        modalidad: "",
        forma: "",
        importe: "",
        cuota: "",
        parcial: "",
        numero: "",
        embargo: "",
        rescate: "",
	});

    const handleChange = (e) => {
		setData({
		  ...data,
		  [e.target.name]: e.target.value,
		});
	};

    return (   
        <div>     
            <div className='box-documento'>
                <div className='boxN title-doc'>
                    <h3>Rescates</h3>
                </div>
                <div className='box-overflow'>
                    <div className='boxN'>
                        <p className='subtitle-doc'>Número de solicitud <span>1000002</span></p>
                    </div>
                    <div className='boxN display-cont'>
                        <div className='fechas-doc'>
                            <p className='subtitle-doc'>Fecha de concertación</p>
                            <p className='fecha-doc'><BiCalendar/> 29/10/2022</p>
                        </div>
                        <div className='fechas-doc'>
                            <p className='subtitle-doc'>Fecha de liquidación</p>
                            <p className='fecha-doc'><BiCalendar/> 31/10/2022</p>
                        </div>
                    </div>
                    <div className="input-doc">
                        <label>Fondo</label>
                        <input  type="text" name="fondo" defaultValue={data.fondo} onChange={handleChange}></input> 
                    </div>
                    <div className="input-doc">
                        <label>Tipo de valor cuotaparte</label>
                        <input type="text" name="valor" defaultValue={data.valor} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Cuotapartista</label>
                        <input type="text" name="cuotapartista" defaultValue={data.cuotapartista} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Agente colocador</label>
                        <input type="text" name="agente" defaultValue={data.agente} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Sucursal</label>
                        <p>Mendoza</p>   
                    </div>
                    <div className="input-doc">
                        <label>Oficial de cuenta</label>
                        <p>Nombre y Apellido</p>   
                    </div>
                    <div className="input-doc">
                        <label>Condición de ingreso derecho</label>
                        <input type="text" name="condicion" defaultValue={data.condicion} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Canal de venta</label>
                        <p>Online</p>   
                    </div>
                    <div className="input-doc">
                        <label>Modalidad de captación</label>
                        <input type="text" name="modalidad" defaultValue={data.modalidad} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Forma de pago</label>
                        <input type="text" name="forma" defaultValue={data.forma} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Por importe</label>
                        <input type="text" name="importe" defaultValue={data.importe} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Cuota</label>
                        <input type="text" name="cuota" defaultValue={data.cuota} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Parcial</label>
                        <input type="text" name="parcial" defaultValue={data.parcial} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Total</label>
                        <p>1000</p>   
                    </div>
                    <div className="input-doc">
                        <label>Número de cuenta</label>
                        <input type="text" name="numero" defaultValue={data.numero} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Embargo</label>
                        <input type="text" name="embargo" defaultValue={data.embargo} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Rescate liquidado anterior</label>
                        <input type="text" name="rescate" defaultValue={data.rescate} onChange={handleChange}></input>
                    </div>
                </div>  
                <div className="botonera-doc">
                    <button className="btnLine">Cancelar</button>
                    <button className="btnColor">Rescatar</button>
                </div>
            </div>
        </div>      
    )*/
}

export default Rescates ;