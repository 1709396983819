import React, { useEffect, useState } from "react";
import WidgetFondos from "../widgets/WidgetFondos";
import WidgetRescate from "../widgets/WidgetRescate";
import WidgetSuscripciones from "../widgets/WidgetSuscripciones";
import WidgetVcp from "../widgets/WidgetVcp";
import WidgetCartera from "../widgets/WidgetCartera";
import WidgetDetalleSuscripciones from "../widgets/WidgetDetalleSuscripciones";
import WidgetDetalleRescate from "../widgets/WidgetDetalleRescate";
import WidgetLiquidez from "../widgets/WidgetLiquidez";
import WidgetOperaciones from "../widgets/WidgetOperaciones";

import { useDispatch, useSelector } from "react-redux";
import "./layout.css";
import axios from "axios";
import urlBack from "../../utilities/urlBack";

const Layout2B = () => {
  const dispatch = useDispatch();
 
  const [position1, setPosition1] = useState({
    type: "",
    position: "",
    id: "",
  });

  const [position2, setPosition2] = useState({
    type: "",
    position: "",
    id: "",
  });

  const workspace = useSelector((state) => state.WorkspaceReducer);
  const widgetArray = useSelector((state) => state.WidgetsReducer);


  const addWidget = (widget) =>{
    switch (widget.position) {
      case 1:
        setPosition1({
          ...position1,
          type: widget.type,
          position: widget.position,
          id: widget.id,
        });
        break;
      case 2:
        setPosition2({
          ...position2,
         type: widget.type,
          position: widget.position,
          id: widget.id,
        });
        break;
      default:
        break;
      }
  };

  useEffect(() => {
    axios
      .get(urlBack + "/workspace/findWidgets?idWorkspace=" + workspace.id)
      .then((res) => {
  
        res.data.forEach((widget) => addWidget(widget));
      });


    if(widgetArray.widgets !== [] && widgetArray.widgets.length > 0 && widgetArray.widgets.length <= 2){
      widgetArray.widgets.forEach((widget) => addWidget(widget));
    }else{
      setPosition1({
        ...position1,
        type: "",
        position: "",
        id: "",
      });
      setPosition2({
        ...position2,
        type: "",
        position: "",
        id: "",
      });
    }
  }, [widgetArray]);

  const posicionar = (valor) => {
    if (valor != null) {
      dispatch({
        type: "posicionar-widget",
        payload: {
          posicion: valor,
          workspace: workspace.id
        }
      });
      return true;
    }
    return false;
  };

 

  return (
    <div className="layout">
      <div className="layout2b">
          {position1.position !== "" && position1.position === 1 && position1.type === 1 &&
            <WidgetFondos position={position1}/>
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 2 &&
            <WidgetVcp position={position1}/>
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 3 &&
            <WidgetSuscripciones position={position1}/>
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 4 &&
            <WidgetRescate position={position1}/>
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 5 &&
            <WidgetCartera position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 6 &&
            <WidgetDetalleSuscripciones position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 7 &&
            <WidgetDetalleRescate position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 8 &&
            <WidgetLiquidez position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 9 &&
            <WidgetOperaciones position={position1} />
          }
          

          {position1.position === "" &&
          <div className="widget allingcenter">
            <div className="buttom-layout2b"> 
              <button  onClick={() => posicionar("1")} className="button-create">
                <b>+</b>
              </button>
            </div>
            </div>
          }

          {position2.position !== "" && position2.position === 2 && position2.type === 1 &&
            <WidgetFondos position={position2}/>
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 2 &&
            <WidgetVcp position={position2}/>
          } 
          {position2.position !== "" && position2.position === 2 && position2.type === 3 &&
            <WidgetSuscripciones position={position2}/>
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 4 &&
            <WidgetRescate position={position2}/>
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 5 &&
            <WidgetCartera position={position2} />
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 6 &&
            <WidgetDetalleSuscripciones position={position2} />
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 7 &&
            <WidgetDetalleRescate position={position2} />
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 8 &&
            <WidgetLiquidez position={position2} />
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 9 &&
            <WidgetOperaciones position={position2} />
          }

          {position2.position === "" &&
          <div className="widget allingcenter">
            <div className="buttom-layout2b"> 
              <button onClick={() => posicionar("2")} className="button-create"> 
                <b>+</b>
              </button>
            </div>
            </div>
          }

      </div>
    </div>
  );
};
export default Layout2B;
