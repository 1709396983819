export default function FiltrosReducer (state = { Filtros:JSON }, action){
    switch (action.type) {
        case "filtros":
            return{
                ...state,
                idcarrusel: action.payload.idcarrusel,
                idwidget: action.payload.idwidget,
          
                
            };
            default:
                return state;
    }
}