import React, { useEffect, useState } from "react";
import WidgetFondos from "../widgets/WidgetFondos";
import WidgetRescate from "../widgets/WidgetRescate";
import WidgetSuscripciones from "../widgets/WidgetSuscripciones";
import WidgetVcp from "../widgets/WidgetVcp";
import WidgetCartera from "../widgets/WidgetCartera";
import WidgetDetalleSuscripciones from "../widgets/WidgetDetalleSuscripciones";
import WidgetDetalleRescate from "../widgets/WidgetDetalleRescate";
import WidgetLiquidez from "../widgets/WidgetLiquidez";
import WidgetOperaciones from "../widgets/WidgetOperaciones";

import { useDispatch, useSelector } from "react-redux";
import "./layout.css";
import axios from "axios";
import urlBack from "../../utilities/urlBack";

const Layout1A = () => {
  const dispatch = useDispatch();

  const [position1, setPosition1] = useState({
    type: "",
    position: "",
    id: ""
  });


  const workspace = useSelector((state) => state.WorkspaceReducer);
  const widgetArray = useSelector((state) => state.WidgetsReducer);



  const addWidget = (widget) =>{
    setPosition1({
      ...position1,
      type: widget.type,
      position: widget.position,
      id: widget.id,
    });
  };

  useEffect(() => {
    axios
      .get(urlBack + "/workspace/findWidgets?idWorkspace=" + workspace.id)
      .then((res) => {
        
        res.data.forEach((widget) => addWidget(widget));
      });

    if(widgetArray.widgets !== []  && widgetArray.widgets.length === 1){
      widgetArray.widgets.forEach((widget) => addWidget(widget));
    }else{
      setPosition1({
        ...position1,
        type: "",
        position: "",
        id: "",
      });
    }
  }, [widgetArray]);

  const posicionar = (valor) => {
    if (valor != null) {
      dispatch({
        type: "posicionar-widget",
        payload: {
          posicion: valor,
          workspace: workspace.id
        }
      });
    }
    return true;
  };

 
  return (
    <div className="layout">

      <div className="layout1a">
          {position1.position !== "" && position1.position === 1 && position1.type === 1 &&
            <WidgetFondos position={position1}/>
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 2 &&
            <WidgetVcp position={position1}/>
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 3 &&
            <WidgetSuscripciones position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 4 &&
            <WidgetRescate position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 5 &&
            <WidgetCartera position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 6 &&
            <WidgetDetalleSuscripciones position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 7 &&
            <WidgetDetalleRescate position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 8 &&
            <WidgetLiquidez position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 9 &&
            <WidgetOperaciones position={position1} />
          }

          {position1.position === "" &&
            <div className="widget allingcenter">
              
              <div className="buttom-layout1a"> 
              <button className="button-create"  onClick={() => posicionar("1")}>
                <b>+</b>
              </button>
              </div>
            </div>
          }

      </div>
    </div>
  );
};
export default Layout1A;
