import React, { useEffect } from 'react';

import './home.css';
import Footer from '../../components/footer/Footer';

import { useSelector } from "react-redux";
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import SelectorWidgets from '../../components/Modals/SelectorWidgets';
import DesancladoModal from '../../components/Modals/DesancladoModal';
import AncladoModal from '../../components/Modals/AncladoModal';
import FiltroModal from '../../components/Modals/FiltroModal';
import { useNavigate } from 'react-router-dom';
import CerrarSesionModal from '../../components/Modals/CerrarSesionModal';
import EliminarWidgetModal from '../../components/Modals/EliminarWidgetModal';

import Layouts from './Layouts';
import Rescates from './Rescates';
import Suscripciones from './Suscripciones';
import Transferencias from './Transferencias';

const Home = () => {
    
  const navigate = useNavigate();
  const token = useSelector((state) => state.TokenReducer);
  //const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if(token.token === ""){
      navigate("/");
    }
  }, [token])

    const workspace = useSelector((state) => state.WorkspaceReducer);
    const bodyhome = useSelector((state) => state.BodyHomeReducer)

    return (
        <div className="App">

          <Navbar workspace={workspace}></Navbar>
          
          <div className='container'>

            <Sidebar/>
            <div className='home'>
              { bodyhome.type === "layouts" && 
                <Layouts/> 
              }
              { bodyhome.type === "rescates" && 
                <Rescates/> 
              }
              { bodyhome.type === "suscripciones" && 
                <Suscripciones/> 
              }
              { bodyhome.type === "transferencias" && 
                <Transferencias/> 
              }   
            </div>
  
            <SelectorWidgets></SelectorWidgets>
            <DesancladoModal></DesancladoModal>
            <AncladoModal></AncladoModal>
            <FiltroModal></FiltroModal>
            <CerrarSesionModal></CerrarSesionModal>
            <EliminarWidgetModal></EliminarWidgetModal>
          </div>
          <Footer/> 
        </div>
    )
}
export default Home ;
