import React, { useState } from 'react';
import './documentos.css';
import { BiCalendar } from "react-icons/bi";

const Suscripciones = () => {

    const [data, setData] = useState({
        fondo: "",
        valor: "",
        cuotapartista: "",
		agente: "",
		condicion: "",
        forma: "",
        importe: "",
        especies: "",
        denominacion: "",
        depositario: "",
        vnominal: "",
        precio: "",
		vmercado: "",
		vmanual: "",
		movimiento: "",
	});

    const handleChange = (e) => {
		setData({
		  ...data,
		  [e.target.name]: e.target.value,
		});
	};

    return (   
        <div>     
            <div className='box-documento'>
                <div className='boxN title-doc'>
                    <h3>Suscripción</h3>
                </div>
                <div className='box-overflow'>
                    <div className='boxN'>
                        <p className='subtitle-doc'>Número de solicitud <span>1000002</span></p>
                    </div>
                    <div className='boxN display-cont'>
                        <div className='fechas-doc'>
                            <p className='subtitle-doc'>Fecha de concertación</p>
                            <p className='fecha-doc'><BiCalendar/> 29/10/2022</p>
                        </div>
                        <div className='fechas-doc'>
                            <p className='subtitle-doc'>Fecha de liquidación</p>
                            <p className='fecha-doc'><BiCalendar/> 31/10/2022</p>
                        </div>
                    </div>
                    <div className="input-doc">
                        <label>Fondo</label>
                        <input  type="text" name="fondo" defaultValue={data.fondo} onChange={handleChange}></input> 
                    </div>
                    <div className="input-doc">
                        <label>Tipo de valor cuotaparte</label>
                        <input type="text" name="valor" defaultValue={data.valor} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Cuotapartista</label>
                        <input type="text" name="cuotapartista" defaultValue={data.cuotapartista} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Agente colocador</label>
                        <input type="text" name="agente" defaultValue={data.agente} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Sucursal</label>
                        <p>Mendoza</p>   
                    </div>
                    <div className="input-doc">
                        <label>Oficial de cuenta</label>
                        <p>Nombre y Apellido</p>   
                    </div>
                    <div className="input-doc">
                        <label>Condición de ingreso derecho</label>
                        <input type="text" name="condicion" defaultValue={data.condicion} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Forma de cobro</label>
                        <input type="text" name="forma" defaultValue={data.forma} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Por importe</label>
                        <input type="text" name="importe" defaultValue={data.importe} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Especies</label>
                        <input type="text" name="especies" defaultValue={data.especies} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Denominación</label>
                        <input type="text" name="denominacion" defaultValue={data.denominacion} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Depositario</label>
                        <input type="text" name="depositario" defaultValue={data.depositario} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Valor nominal</label>
                        <input type="text" name="vnominal" defaultValue={data.vnominal} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Precio</label>
                        <input type="text" name="precio" defaultValue={data.precio} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Valor mercado</label>
                        <input type="text" name="vmercado" defaultValue={data.vmercado} onChange={handleChange}></input>
                    </div>
					<div className="input-doc">
                        <label>Valor manual</label>
                        <input type="text" name="vmanual" defaultValue={data.vmanual} onChange={handleChange}></input>
                    </div>
					<div className="input-doc">
                        <label>Movimiento interno</label>
                        <input type="text" name="movimiento" defaultValue={data.movimiento} onChange={handleChange}></input>
                    </div>
                </div>  
                <div className="botonera-doc">
                    <button className="btnLine">Cancelar</button>
                    <button className="btnColor">Rescatar</button>
                </div>
            </div>
        </div>      
    )
}

export default Suscripciones;
