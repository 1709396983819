import React, { useState } from 'react';
import './documentos.css';

const Transferencias=()=>{

    const [data, setData] = useState({
        tnegativa: "",
		tpositiva: "",
        fondo: "",
        valor: "",
		fecha: "",
        nombre: "",
        numero: "",
        nombre2: "",
        numero2: "",
        ingreso: "",
        egreso: "",
        cantidad: "",
        cuota: "",
	});

    const handleChange = (e) => {
		setData({
		  ...data,
		  [e.target.name]: e.target.value,
		});
	};

    return (   
        <div>     
            <div className='box-documento'>
                <div className='boxN title-doc'>
                    <h3>Transferencia</h3>
                </div>
                <div className='box-overflow'>
                    <div className="input-doc">
                        <label>Transferencia negativa</label>
                        <input  type="text" name="tnegativa" defaultValue={data.tnegativa} onChange={handleChange}></input> 
                    </div>
                    <div className="input-doc">
                        <label>Transferencia positiva</label>
                        <input  type="text" name="tpositiva" defaultValue={data.tpositiva} onChange={handleChange}></input> 
                    </div>
                    <div className="input-doc">
                        <label>Fondo</label>
                        <input  type="text" name="fondo" defaultValue={data.fondo} onChange={handleChange}></input> 
                    </div>
                    <div className="input-doc">
                        <label>Tipo de valor de cuenta</label>
                        <input type="text" name="valor" defaultValue={data.valor} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Fecha de concertación</label>
                        <input type="text" name="fecha" defaultValue={data.fecha} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <p><b>Cuotapartista emisor</b></p>   
                    </div>
                    <div className="input-doc">
                        <label>Nombre</label>
                        <input type="text" name="nombre" defaultValue={data.nombre} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Número</label>
                        <input type="text" name="numero" defaultValue={data.numero} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <p><b>Cuotapartista emisor</b></p>   
                    </div>
                    <div className="input-doc">
                        <label>Nombre</label>
                        <input type="text" name="nombre2" defaultValue={data.nombre2} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Número</label>
                        <input type="text" name="numero2" defaultValue={data.numero2} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Condición de ingreso</label>
                        <input type="text" name="ingreso" defaultValue={data.ingreso} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Condición de egreso</label>
                        <input type="text" name="egreso" defaultValue={data.egreso} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <p><b>Posición</b></p>   
                    </div>
                    <div className="input-doc">
                        <label>Cantidad de cuotas</label>
                        <input type="text" name="cantidad" defaultValue={data.cantidad} onChange={handleChange}></input>
                    </div>
                    <div className="input-doc">
                        <label>Carga Cuota</label>
                        <input type="text" name="cuota" defaultValue={data.cuota} onChange={handleChange}></input>
                    </div>
                </div>  
                <div className="botonera-doc">
                    <button className="btnLine">Cancelar</button>
                    <button className="btnColor">Rescatar</button>
                </div>
            </div>
        </div>       
    )
}

export default Transferencias ;