import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import './login.css';
import logo from '../../assets/imagenes/logo-byma.svg';
import { useNavigate  } from "react-router-dom";

import urlBack from '../../utilities/urlBack';
import ErrorLoginModal from '../Modals/ErrorLoginModal';

const Login=()=>{

    const dispatch = useDispatch();

    const navigate = useNavigate();
   
    let qs = require('qs');
    let body = qs.stringify({
      'grant_type': 'password',
      'username': 'quinto',
      'password': 'Quinto.22!' 
    });
    let config = {
      method: 'post',
      url: 'https://sandbox-oms.vantek.io:443/generic-oauth-core/oauth/token',
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'no-cache',
        'Authorization': 'Basic YXBpX2tleV9xdWludG86NTcwSW1wNGE0NzA=',
      },
      data : body
    };

    const [data, setData] = useState({
		email: "",
		password: "",
	});

	const handleChange = (e) => {
		setData({
		  ...data,
		  [e.target.name]: e.target.value,
		});
	};

    const sendDataUser =  (jwt) => {
      dispatch({
			type: 'TOKEN',
			payload:jwt
		});
    };  

 const shotNotif = (titulo, mensaje) => {
    dispatch({
        type: "showNotification",
        data: {
            notificationType: "notificationCommon",
            show: true,
            title: titulo,
            text: mensaje,
        }
    });
}

    const onSubmit = () => {
        axios.get(urlBack + "/user/login?mail=" + data.email + "&pass=" + data.password)
        .then((res) => {
            sendDataUser(res.data.JWT);
            if (res.status === 200) {
                dispatch({
                    type: "showNotification",
                    data: {
                        notificationType: "notificationCommon",
                        show: false,
                    }
                });
                
                navigate("/Home");
              }

        })
        
        .catch((err) => {
            shotNotif("Ha ocurrido un error durante el ingreso a la plataforma", "El nombre de usuario o contraseña proporcionados son incorrectos.");
            dispatch({type:'modal-ErrorLogin', payload:"abierto"});
        });
        
        
        
        axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    return (   
        <div>     
            <div className='container bo-fondo'>
            <ErrorLoginModal />
                <div className='formLogin'>
                    <div className='loginHeader'>
                        <img src={logo} alt="Logo BYMA"/>
                    </div>
                    <div className='containerForm'>
                        <div className='boxInput'>
                            <input placeholder="Usuario" type="email" name="email" value={data.email} onChange={handleChange} />
                        </div>
                        <div className='boxInput'>
                            <input placeholder="Contraseña" name="password" type="password" value={data.password} onChange={handleChange} />
                        </div>
                        <div className='links'>
                            <label for="rememberMe" className='remember'>
                                <input type="checkbox" value="lsRememberMe" id="rememberMe" />
                                <span class="checkmark"></span> 
                                Recordarme
                            </label>
                            <a href="#" className="link">¿Olvidó su contraseña?</a>
                        </div>
                        <div className='boxButton'>
                            <button type="button" onClick={onSubmit} >Ingresar</button>
                        </div>
                        <div className='links2'>
                            <p>¿No puedes ingresar? <a href="#" className="link">Contactanos</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>      
    )
}

export default Login ;