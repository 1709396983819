import React from 'react'
import './card.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useDispatch, useSelector } from 'react-redux';
import urlBack from '../../utilities/urlBack';
import axios from 'axios';


 const Card = (props) => {

    const dispatch = useDispatch();

    
    const activo = useSelector((state) => state.EstadoWorkspaceReducer);
    
    
    const desanclar = () => {
      dispatch({
        type: "abrir-modal-desanclado",
        payload: props.workspace.id,
      });
    }


    const selectWorkspace = (workspace) => {

        dispatch({
            type: "WORKSPACE",
            payload: {
              id: workspace.id,
              name: workspace.name,
              description: workspace.description,
              layout: workspace.layout.description,
            },
          });
          
          dispatch({
            type: "WorkspaceDesanaclaje",
            payload: props.workspace.id,
          });

          dispatch({
            type: "WorkspaceAnclaje",
            payload: props.workspace.id,
          });
    

        axios.get(urlBack + "/workspace/findWidgets?idWorkspace=" + workspace.id)
          .then((re) => {
            dispatch({
              type: "WIDGETS",
              payload: re.data,
            });
        });

        dispatch({
          type: "workspace-activo",
          payload: workspace.id,
        })
          
    };

    return (
     
        <div className="card">
            <div id={props.workspace.id} className={props.workspace.id == activo.workspace ? "cardWrapperactive" : "cardWrapper"}>
                <div className="cardIcon" onClick={desanclar}>
                    <CloseRoundedIcon sx={{ fontSize: 15 }} className="closeCard"  />
                </div>
                <div className="cardContent" onClick={() => selectWorkspace(props.workspace)}>
                    <span className="cardTitle">{props.workspace.name}</span>
                    <span className="cardDescription">{props.workspace.description}</span>
                </div>
            </div>
        </div>
          
     
    )
}

export default Card;
