export default function  EstadoModalReducer (state = { estadomodal: "" }, action){
    switch (action.type) {
        case "SETESTADO":
            return{
                ...state,
                estadomodal: action.payload,
             
          
                
            };
            default:
                return state;
    }
}