import React, { Fragment } from 'react';
import StepWizard from 'react-step-wizard';

const NavForm = (props) => {
	const dots = [];
	for (let i = 1; i <= props.totalSteps-1; i += 1) {
		const isActive = props.currentStep === i;
		const isPrev = props.currentStep > i;
		dots.push((
			<div className={`step txtCenter ${isActive ? 'active' : ''} ${isPrev ? 'finish' : ''}`} key={`step-${i}`}>
				<div className="num flex flexAligner flexCenter">
					{
					isPrev ?
					<div className="success-checkmark yes">
						<span />
					</div>
					:
					<span>{i}</span>
					}
				</div>
			</div>
		));
	}
	const { children } = props;
	return (
		<Fragment>
			{/* <div className="stepper flex flexAligner flexBetween">
				{dots}
			</div> */}
			<div>
				{children}
			</div>
		</Fragment>
	);
}

const Stepper = (props) => {
	const { children } = props;
	const changeStep = (steps) => {
		props.setStep(steps.activeStep);
	}
	return (
		<StepWizard nav={<NavForm/>} onStepChange={changeStep} initialStep={1}>
			{children}
		</StepWizard>
	)
}

export default Stepper;
