import React, { useEffect, useState } from 'react';
import './widget.css';
import { AiOutlineExpand, AiOutlineCompress, AiOutlineClose } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import {  AngleUpIcon, AngleDownIcon} from 'react-line-awesome'
import axios from 'axios';
import urlFunds from '../../utilities/urlFunds';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';

export default function WidgetFondos(props) {
    const [isNormal, setNormal] = useState("false");
    const [estado, setEstado] = useState(null);
    
    const [sortedCNV, setSortedCNV] = useState({
        property: "code",
        ascending: "",
    });

    const [sortedFund, setSortedFund] = useState({
        property: "name",
        ascending: "",
    });

    const [sortedMoney, setSortedMoney] = useState({
        property: "money",
        ascending: "",
    });
    const dispatch = useDispatch();
    
    const cerrarExtension = () => {
        setEstado(null);
    };

    const filtroGeneral = useSelector((state) => state.FiltroGeneralReducer);
    const [funds, setFunds] = useState([]);
    const [total, setTotal] = useState(0.0);
  
    useEffect(() => {
        let json = {
          page: 0,
          size: 20,
          sorting: [
            sortedFund,
            sortedCNV,
            sortedMoney,
          ],
        };

        if(filtroGeneral.filtros !== null && filtroGeneral.filtros !== undefined && Array.isArray(filtroGeneral.filtros)){
          json = {...json,
            filters: filtroGeneral.filtros
          }
        }
        
        axios.post(urlFunds + "/api/funds/list-funds", JSON.stringify(json), {
            headers: {
              "content-type": "application/json",
            },
          }).then((response) => {
              setFunds(response.data.results);
              setTotal(response.data.totalized);
          });
        
    }, [sortedFund, sortedCNV, sortedMoney, filtroGeneral])
   
    const sortFund = () => {
        let value = "";
        if(sortedFund.ascending === ""){
           value = "true";
        } else if (sortedFund.ascending === "true"){
            value = "false";
        } 

        setSortedFund({...sortedFund,
            ascending: value
        });
    };

    const sortCNV = () => {
        let value = "";
        if(sortedCNV.ascending === ""){
           value = "true";
        } else if (sortedCNV.ascending === "true"){
            value = "false";
        } 

        setSortedCNV({...sortedCNV,
            ascending: value
        });
    };

    const sortMoney = () => {
        let value = "";
        if(sortedMoney.ascending === ""){
           value = "true";
        } else if (sortedMoney.ascending === "true"){
            value = "false";
        } 

        setSortedMoney({...sortedMoney,
            ascending: value
        });
    };
    
    const handleToggle = () => {
      setNormal(!isNormal);
      setEstado("open");
    };

    const EliminarWidget = (id) => {
        dispatch({
          type: "modal-eliminar-widget",
          payload: {
            estado: "abierto",
            idWidget: id,
          },
        });
       
    }
    
    const FiltroModal = (id) => {
        dispatch({
          type: "abrir-modal-filtroModal",
          payload: {
            estado: "abierto",
            idWidget: id,
          },
        });}

    return (
        
      <div className="widget">
          <div className='widgetHeader'>
                <div className='widgetHeaderLeft'>
                    <span className='widgetTitle'>Fondos</span>
                </div>
                <div className='widgetHeaderRight'>
                  <p title="Filtrar Elementos" className="linkFiltroModal" onClick={FiltroModal}>
                    <FaFilter component="span" className="widgetToolbar" />     
                  </p>
                  <p className="d-block"  title="Expandir Elemento" onClick={handleToggle}>
                    <AiOutlineExpand component="span" className="widgetToolbar" />    
                  </p> 
                  <p title="Cerrar Elemento" onClick={() =>EliminarWidget(props.position.id)}>
                    <AiOutlineClose component="span" className="widgetToolbar" />    
                  </p> 
                </div>
          </div>
          <div className='widgetTableContainer'>
            <table className='widgetTable'>
                <thead>
                    <tr className='widgetTr' style={{width : '100%'}}>
                        <th className='widgetTh' style={{width : '60%', textAlign : 'left'}}>
                            <p><span onClick={sortFund} className="sortColumn">Fondo</span>
                                <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                                <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                            </p>
                        </th>
                        <th className='widgetTh' style={{width : '25%'}}>
                            <p><span onClick={sortCNV} className="sortColumn">CNV</span>
                                <AngleUpIcon component="span" className={sortedCNV.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                                <AngleDownIcon component="span" className={sortedCNV.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                            </p>
                        </th>
                        <th className='widgetTh' style={{width : '15%'}}>
                            <p><span onClick={sortMoney} className="sortColumn">Moneda</span>
                                <AngleUpIcon component="span" className={sortedMoney.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                                <AngleDownIcon component="span" className={sortedMoney.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody className="h-200">
                    {funds.map((fund) => (
                        <tr className='widgetTrData' style={{width : '100%'}}>
                            <td className='widgetTdLeft' style={{width : '60%', textAlign : 'left'}}>{fund.abbreviation}</td>
                            <td className='widgetTd' style={{width : '25%'}}>{fund.code}</td>
                            <td className='widgetTdRight' style={{width : '15%'}}>{fund.money}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
          </div>
          <div className='widgetFooter'>
              <span className="widgetFooterTotal">
                  Total de Fondos: <span><NumberFormat value={total} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={'$'} /></span>
              </span>
          </div>

          <div className={estado != null ? "widget fullscreen" : "d-none"}>
            <div className='widgetHeader'>
              <div className='widgetHeaderLeft'>
                  <span className='widgetTitle'>Fondos</span>
              </div>
              <div className='widgetHeaderRight'>
                  <p title="Filtrar Elementos" className="linkFiltroModal" onClick={FiltroModal}>
                    <FaFilter component="span" className="widgetToolbar" />      
                  </p>
                  <p className="d-block" title="Contraer Elemento" onClick={cerrarExtension}>
                    <AiOutlineCompress component="span" className="widgetToolbar" />    
                  </p> 
              </div>
            </div>
            <div className='widgetTableContainer'>
                <table className='widgetTable'>
                    <thead>
                    <tr className='widgetTr' style={{width : '100%'}}>
                        <th className='widgetTh'>
                            <p className="sortColumn">Abreviatura</p>
                            <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                            <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                        </th>
                        <th className='widgetTh'>
                            <p onClick={sortFund} className="sortColumn">Nombre</p>
                            <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                            <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                        </th>
                        <th className='widgetTh'>
                            <p className="sortColumn">Clase</p>
                            <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                            <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                        </th>
                        <th className='widgetTh'> 
                            <p onClick={sortCNV} className="sortColumn">CNV</p>
                            <AngleUpIcon component="span" className={sortedCNV.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                            <AngleDownIcon component="span" className={sortedCNV.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                        </th>
                        <th className='widgetTh'>
                            <p className="sortColumn">CAFCI</p>
                            <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                            <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                        </th>
                        <th className='widgetTh'>
                            <p onClick={sortMoney} className="sortColumn">Moneda</p>
                            <AngleUpIcon component="span" className={sortedMoney.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                            <AngleDownIcon component="span" className={sortedMoney.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                        </th>
                        <th className='widgetTh'>
                            <p className="sortColumn">Renta</p>
                            <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                            <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                        </th>
                        <th className='widgetTh'>  
                            <p className="sortColumn">Riesgo</p>
                            <AngleUpIcon component="span" className={sortedMoney.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                            <AngleDownIcon component="span" className={sortedMoney.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                        </th>
                        <th className='widgetTh'>
                            <p className="sortColumn">Región</p>
                            <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                            <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                        </th>
                        <th className='widgetTh'>  
                            <p className="sortColumn">Depositaria</p>
                            <AngleUpIcon component="span" className={sortedMoney.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                            <AngleDownIcon component="span" className={sortedMoney.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                        </th>
                        <th className='widgetTh'>
                            <p className="sortColumn">Benchmark</p>
                            <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                            <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                        </th>
                        <th className='widgetTh'>  
                            <p className="sortColumn">Duration</p>
                            <AngleUpIcon component="span" className={sortedMoney.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                            <AngleDownIcon component="span" className={sortedMoney.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                        </th>
                    </tr>
                    </thead>
                    <tbody className="h-auto" >
                        {funds.map((fund) => (
                            <tr className='widgetTrData' style={{width : '100%'}}>
                                <td className='widgetTd'></td>
                                <td className='widgetTd'>{fund.abbreviation}</td>
                                <td className='widgetTd'></td>
                                <td className='widgetTd'>{fund.code}</td>
                                <td className='widgetTd'></td>
                                <td className='widgetTd'>{fund.money}</td>
                                <td className='widgetTd'></td>
                                <td className='widgetTd'></td>
                                <td className='widgetTd'></td>
                                <td className='widgetTd'></td>
                                <td className='widgetTd'></td>
                                <td className='widgetTd'></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='widgetFooter'>
                <span className="widgetFooterTotal">
                    Total de Fondos: <span><NumberFormat value={total} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={'$'} /></span>
                </span>
            </div>
        </div>
      </div>
    )
}