import React, { useState } from 'react';

const Step3=(props)=>{

    const [data, setData] = useState({
        numero: "",
        embargo: "",
        rescate: "",
	});

    const handleChange = (e) => {
		setData({
		  ...data,
		  [e.target.name]: e.target.value,
		});
	};
    const applyNextStep = () => {
		
        props.goToStep(1);

}
const applyPreviousStep = () => {
		
    props.goToStep(2);

}

    return (   
        <div>     
            <div className='box-documento'>
                <div className='box-dots'>
                    <div className='dot dot-active'></div>
                    <hr className='hr-dot hr-active'/>
                    <div className='dot dot-active'></div>
                    <hr className='hr-dot hr-active'/>
                    <div className='dot dot-active'></div>
                </div>
                <div className="input-doc">
                    <label>Número de cuenta</label>
                    <input type="text" name="numero" defaultValue={data.numero} onChange={handleChange}></input>
                </div>
                <div className="input-doc">
                    <label>Embargo</label>
                    <input type="text" name="embargo" defaultValue={data.embargo} onChange={handleChange}></input>
                </div>
                <div className="input-doc">
                    <label>Rescate liquidado anterior</label>
                    <input type="text" name="rescate" defaultValue={data.rescate} onChange={handleChange}></input>
                </div>
                <div className="botonera-doc">
                    <button className="btnLine"onClick={() => applyPreviousStep()}>Volver</button>
                    <button className="btnColor"onClick={() => applyNextStep()}>Rescatar</button>
                </div>
            </div>
        </div>      
    )
}

export default Step3 ;