import React, { useState } from 'react';
import './sidebar.css';
import { GoGear } from "react-icons/go";
import { FiHelpCircle } from "react-icons/fi";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { RiLayoutMasonryFill }  from "react-icons/ri";
import { useDispatch } from "react-redux";

export default function Sidebar() {

    const dispatch = useDispatch();

    const [Active, setActive] = useState("escritorios");

    const escritorios = () =>{
        dispatch({
            type: "SetBodyHome",
            payload: "layouts",
          });
          setActive("escritorios");
    }
    const rescates = () =>{
        dispatch({
            type: "SetBodyHome",
            payload: "rescates",
          });
          setActive("rescates");
    }
    const suscripciones = () =>{
        dispatch({
            type: "SetBodyHome",
            payload: "suscripciones",
          });
          setActive("suscripciones");
    }
    const transferencias = () =>{
        dispatch({
            type: "SetBodyHome",
            payload: "transferencias",
          });
          setActive("transferencias");
    }

    return (
        <div className='sidebar'>
            <div className="sidebarWrapper">
                <div className="sidebarMenu">
                    <ul className="sidebarList">
                        <li className={Active === "escritorios" ? 'sidebarListItem active': 'sidebarListItem'} onClick={escritorios}>
                            <RiLayoutMasonryFill component="span" className="iconSidebar" />
                            Escritorios
                        </li>
                        <li className={Active === "rescates" ? 'sidebarListItem active': 'sidebarListItem'} onClick={rescates}>
                            <BsFillFileEarmarkTextFill component="span" className="iconSidebar" />
                            Rescates
                        </li>
                        <li className={Active === "suscripciones" ? 'sidebarListItem active': 'sidebarListItem'} onClick={suscripciones}>
                            <BsFillFileEarmarkTextFill component="span" className="iconSidebar" />
                            Suscripciones
                        </li>
                        <li className={Active === "transferencias" ? 'sidebarListItem active': 'sidebarListItem'} onClick={transferencias}>
                            <BsFillFileEarmarkTextFill component="span" className="iconSidebar" />
                            Transferencias
                        </li>
                        <li className={Active === "eventos" ? 'sidebarListItem active': 'sidebarListItem'}>
                            <FaCalendarAlt component="span" className="iconSidebar" />
                            Eventos
                        </li>
                        <li className={Active === "cuenta" ? 'sidebarListItem active': 'sidebarListItem'}>
                            <FaUserAlt component="span" className="iconSidebar" />
                            Cuenta
                        </li>
                    </ul>
                    <ul className="sidebarList">
                        <li className="sidebarListItem">
                            <GoGear component="span" className="iconSidebar" />
                            Ajustes
                        </li>
                        <li className="sidebarListItem">
                            <FiHelpCircle component="span" className="iconSidebar" />
                            Ayuda
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
