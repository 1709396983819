import React from "react";
import "./onboarding.css";
import Imagen1 from "../../assets/icons/Iconos-onboarding/hola.png";
import Imagen2 from "../../assets/icons/Iconos-onboarding/mano.png";
import Imagen3 from "../../assets/icons/Iconos-onboarding/finanzas.png";
const Onboarding = () => {
  return (
        <div className="wrapper">
            <div className="box a">
                <img src={Imagen1} alt="Imagen" />
                <p>Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.</p>
            </div>
            <div className="box b">
                <img src={Imagen2} alt="Imagen" />
                <p>Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.</p>
            </div>
            <div className="box c">
                <img src={Imagen3} alt="Imagen" />
                <p>Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.</p>
            </div>
            <div className="box d">
                <p className="comenzar">Comenzar</p>
            </div>
        </div>
  );
};
export default Onboarding;