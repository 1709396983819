import React, { useEffect, useState } from 'react';
import './widget.css'
import { useDispatch, useSelector } from 'react-redux';
import { FaFilter } from "react-icons/fa";
import { AiOutlineExpand, AiOutlineCompress, AiOutlineClose } from "react-icons/ai";
import { AngleUpIcon, AngleDownIcon} from 'react-line-awesome'
import axios from 'axios';
import urlFunds from '../../utilities/urlFunds';
  
export default function WidgetVcp(props) {
  const [isNormal, setNormal] = useState("false");
  const [estado, setEstado] = useState(null);

  const dispatch = useDispatch();

  const EliminarWidget = (id) => {
    dispatch({
      type: "modal-eliminar-widget",
      payload: {
        estado: "abierto",
        idWidget: id,
      },
    });}
  
  const FiltroModal = (id) => {
    dispatch({
      type: "abrir-modal-filtroModal",
      payload: {
        estado: "abierto",
        idWidget: id,
      },
    });}
   
  const [sortedDate, setSortedDate] = useState({
      property: "vcpDate",
      ascending: "",
  });

  const [sortedFund, setSortedFund] = useState({
      property: "name",
      ascending: "",
  });

  const [sortedAmount, setSortedAmount] = useState({
      property: "vcp",
      ascending: "",
  });

  const filtroGeneral = useSelector((state) => state.FiltroGeneralReducer);
  const [funds, setFunds] = useState([]);

  const sortFund = () => {
      let value = "";
      if(sortedFund.ascending === ""){
         value = "true";
      } else if (sortedFund.ascending === "true"){
          value = "false";
      } 

      setSortedFund({...sortedFund,
          ascending: value
      });
  };

  useEffect(() => {
    let json = {
      page: 0,
      size: 20,
      sorting: [
        sortedFund,
        sortedAmount,
        sortedDate,
      ],
    };

    if(filtroGeneral.filtros !== null && filtroGeneral.filtros !== undefined && Array.isArray(filtroGeneral.filtros)){
      json = {...json,
        filters: filtroGeneral.filtros
      }
    }
    
    axios.post(urlFunds + "/api/funds/list-vcps", JSON.stringify(json), {
        headers: {
          "content-type": "application/json",
        },
      }).then((response) => {
          setFunds(response.data.results);
      });
    
    }, [sortedFund, sortedAmount, sortedDate, filtroGeneral])

  const handleToggle = () => {
    setNormal(!isNormal);
    setEstado("open")
  };
 
  const cerrarExtension = () => {
            
    setEstado(null);

};

  const sortAmount = () => {
      let value = "";
      if(sortedAmount.ascending === ""){
         value = "true";
      } else if (sortedAmount.ascending === "true"){
          value = "false";
      } 

      setSortedAmount({...sortedAmount,
          ascending: value
      });
  };

  const sortDate = () => {
      let value = "";
      if(sortedDate.ascending === ""){
         value = "true";
      } else if (sortedDate.ascending === "true"){
          value = "false";
      } 

      setSortedDate({...sortedDate,
          ascending: value
      });
  };

    return (
        <div className="widget">
          <div className='widgetHeader'>
              <div className='widgetHeaderLeft'>
                  <span className='widgetTitle'>VCPs</span>
              </div>
              <div className='widgetHeaderRight'>
                  <p title="Filtrar Elementos" className="linkFiltroModal" onClick={FiltroModal}>
                    <FaFilter component="span" className="widgetToolbar" />     
                  </p> 
                  <p className={!isNormal ? "d-none" : "d-block"}  title="Expandir Elemento" onClick={handleToggle}>
                    <AiOutlineExpand component="span" className="widgetToolbar" />    
                  </p> 
                  <p className={isNormal ? "d-none" : "d-block"} title="Contraer Elemento" onClick={handleToggle}>
                    <AiOutlineCompress component="span" className="widgetToolbar" />    
                  </p> 
                  <p title="Cerrar Elemento"onClick={() =>EliminarWidget(props.position.id)}>
                    <AiOutlineClose component="span" className="widgetToolbar" />    
                  </p> 
              </div>
          </div>
          <div className='widgetTableContainer'>
            <table className='widgetTable'>
              <tr className='widgetTr' style={{width : '100%'}}>
                <th className='widgetTh' style={{width : '50%', textAlign : 'left'}}>
                  <p onClick={sortFund} className="sortColumn">Fondo</p>
                  <AngleUpIcon component="span" className={sortedFund.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                  <AngleDownIcon component="span" className={sortedFund.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                </th>
                <th className='widgetTh' style={{width : '20%'}}>
                  <p onClick={sortAmount} className="sortColumn">VCP</p>
                  <AngleUpIcon component="span" className={sortedAmount.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                  <AngleDownIcon component="span" className={sortedAmount.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                </th>
                <th className='widgetTh' style={{width : '20%'}}>
                  <p onClick={sortDate} className="sortColumn">Fecha</p>
                  <AngleUpIcon component="span" className={sortedDate.ascending === "true" ? "d-inline sort" : "d-none"}></AngleUpIcon>
                  <AngleDownIcon component="span" className={sortedDate.ascending === "false" ? "d-inline sort" : "d-none"}></AngleDownIcon>
                </th>
              </tr>
              <tbody className={!isNormal ? "h-auto" : "h-200"}>
                  {funds.map((fund) => (
                    <tr className='widgetTrData' style={{width : '100%'}}>
                      <td className='widgetTdLeft' style={{width : '50%', textAlign : 'left'}}>{fund.abbreviation}</td>
                      <td className='widgetTd' style={{width : '20%'}}>{fund.amount}</td>
                      <td className='widgetTdRight' style={{width : '20%'}}>{fund.date}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className='widgetFooter'>
              <span className="widgetFooterTotal">
                
              </span>
          </div>
          <div className={estado != null ? "widget fullscreen" : "d-none"}>
          <div className='widgetHeader'>
            <div className='widgetHeaderLeft'>
                <span className='widgetTitle'>VCPs</span>
            </div>
            <div className='widgetHeaderRight'>
              <p title="Filtrar Elementos" className="linkFiltroModal" onClick={FiltroModal}>
                <FaFilter component="span" className="widgetToolbar" />      
              </p> 
              <p className="d-block" title="Contraer Elemento" onClick={cerrarExtension}>
                <AiOutlineCompress component="span" className="widgetToolbar" />    
              </p> 
            </div>
          </div>
          <div className='widgetTableContainer'>
            <table className='widgetTable'>
              <thead>
                  <tr className='widgetTr' style={{width : '100%'}}>
                    <th className='widgetTh'>
                      <p className="sortColumn">Abreviatura</p>
                      <AngleUpIcon component="span"></AngleUpIcon>
                      <AngleDownIcon component="span"></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                      <p className="sortColumn">Nombre</p>
                      <AngleUpIcon component="span"></AngleUpIcon>
                      <AngleDownIcon component="span"></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                      <p className="sortColumn">Clase</p>
                      <AngleUpIcon component="span"></AngleUpIcon>
                      <AngleDownIcon component="span"></AngleDownIcon>
                    </th>
                    <th className='widgetTh'> 
                      <p className="sortColumn">CNV</p>
                      <AngleUpIcon component="span"></AngleUpIcon>
                      <AngleDownIcon component="span"></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                      <p className="sortColumn">CAFCI</p>
                      <AngleUpIcon component="span"></AngleUpIcon>
                      <AngleDownIcon component="span"></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                      <p className="sortColumn">Valor de Cuotaparte</p>
                      <AngleUpIcon component="span"></AngleUpIcon>
                      <AngleDownIcon component="span"></AngleDownIcon>
                    </th>
                    <th className='widgetTh'>
                      <p className="sortColumn">Fecha último VCP</p>
                      <AngleUpIcon component="span"></AngleUpIcon>
                      <AngleDownIcon component="span"></AngleDownIcon>
                    </th>
                  </tr>
                </thead>
                <tbody className={!isNormal ? "h-auto" : "h-200"}>
                  {funds.map((fund) => (
                    <tr className='widgetTrData' style={{width : '100%'}}>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                      <td className='widgetTd'></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          <div className='widgetFooter'>
              <span className="widgetFooterTotal">
                
              </span>
          </div>
      </div>

      </div>

    )
}