import React, { useState } from 'react';

const Step2=(props)=>{

    const [data, setData] = useState({
        condicion: "",
        modalidad: "",
        forma: "",
        importe: "",
        cuota: "",
        parcial: "",
	});

    const handleChange = (e) => {
		setData({
		  ...data,
		  [e.target.name]: e.target.value,
		});
	};
    const applyNextStep = () => {
		
        props.goToStep(3);

}
const applyPreviousStep = () => {
		
    props.goToStep(1);

}

    return (   
        <div>     
            <div className='box-documento'>
                <div className='box-dots'>
                    <div className='dot dot-active'></div>
                    <hr className='hr-dot hr-active'/>
                    <div className='dot dot-active'></div>
                    <hr className='hr-dot'/>
                    <div className='dot'></div>
                </div>
                <div className='boxN display-input'>
                    <div className="input-doc">
                        <label>Sucursal</label>
                        <p>Mendoza</p>   
                    </div>
                    <div className="input-doc">
                        <label>Oficial de cuenta</label>
                        <p>Nombre y Apellido</p>   
                    </div>
                    <div className="input-doc">
                        <label>Canal de venta</label>
                        <p>Online</p>   
                    </div>
                </div>
                <div className="input-doc">
                    <label>Condición de ingreso derecho</label>
                    <input type="text" name="condicion" defaultValue={data.condicion} onChange={handleChange}></input>
                </div>
                <div className="input-doc">
                    <label>Modalidad de captación</label>
                    <input type="text" name="modalidad" defaultValue={data.modalidad} onChange={handleChange}></input>
                </div>
                <div className="input-doc">
                    <label>Forma de pago</label>
                    <input type="text" name="forma" defaultValue={data.forma} onChange={handleChange}></input>
                </div>
                <div className="input-doc">
                    <label>Por importe</label>
                    <input type="text" name="importe" defaultValue={data.importe} onChange={handleChange}></input>
                </div>
                <div className="input-doc">
                    <label>Cuota</label>
                    <input type="text" name="cuota" defaultValue={data.cuota} onChange={handleChange}></input>
                </div>
                <div className="input-doc">
                    <label>Parcial</label>
                    <input type="text" name="parcial" defaultValue={data.parcial} onChange={handleChange}></input>
                </div>
                <div className="input-doc">
                    <label>Total</label>
                    <p>1000</p>   
                </div>
                <div className="botonera-doc">
                    <button className="btnLineGrey" onClick={() => applyPreviousStep()}>Volver</button>
                    <button className="btnColorGrey" onClick={() => applyNextStep()}>Siguiente</button>
                </div>
            </div>
        </div>      
    )
}

export default Step2 ;