import React, { useEffect, useState } from "react";
import WidgetFondos from "../widgets/WidgetFondos";
import WidgetRescate from "../widgets/WidgetRescate";
import WidgetSuscripciones from "../widgets/WidgetSuscripciones";
import WidgetVcp from "../widgets/WidgetVcp";
import WidgetCartera from "../widgets/WidgetCartera";
import WidgetDetalleSuscripciones from "../widgets/WidgetDetalleSuscripciones";
import WidgetDetalleRescate from "../widgets/WidgetDetalleRescate";
import WidgetLiquidez from "../widgets/WidgetLiquidez";
import WidgetOperaciones from "../widgets/WidgetOperaciones";


import { useDispatch, useSelector } from "react-redux";
import "./layout.css";
import axios from "axios";
import urlBack from "../../utilities/urlBack";

const Layout6A = () => {
  const dispatch = useDispatch();

  const [position1, setPosition1] = useState({
    type: "",
    position: "",
    id: "",
  });

  const [position2, setPosition2] = useState({
    type: "",
    position: "",
    id: "",
  });

  const [position3, setPosition3] = useState({
    type: "",
    position: "",
    id: "",
  });

  const [position4, setPosition4] = useState({
    type: "",
    position: "",
    id: "",
  });

  const [position5, setPosition5] = useState({
    type: "",
    position: "",
    id: "",
  });

  const [position6, setPosition6] = useState({
    type: "",
    position: "",
    id: "",
  });

  const workspace = useSelector((state) => state.WorkspaceReducer);
  const widgetArray = useSelector((state) => state.WidgetsReducer);



  useEffect(() => {
    axios
      .get(urlBack + "/workspace/findWidgets?idWorkspace=" + workspace.id)
      .then((res) => {

        res.data.forEach((widget) => {
          switch (widget.position) {
            case 1:
              setPosition1({
                ...position1,
               type: widget.type,
          position: widget.position,
          id: widget.id,
        });
              break;
            case 2:
              setPosition2({
                ...position2,
               type: widget.type,
          position: widget.position,
          id: widget.id,
        });
              break;
            case 3:
              setPosition3({
                ...position3,
               type: widget.type,
          position: widget.position,
          id: widget.id,
        });
              break;
            case 4:
              setPosition4({
                ...position4,
               type: widget.type,
          position: widget.position,
          id: widget.id,
        });
              break;
            case 5:
              setPosition5({
                ...position5,
               type: widget.type,
          position: widget.position,
          id: widget.id,
        });
              break;
            case 6:
              setPosition6({
                ...position6,
               type: widget.type,
          position: widget.position,
          id: widget.id,
        });
              break;
            default:
              break;
          }
        });
      });


    if(widgetArray.widgets !== [] && widgetArray.widgets.length > 0 && widgetArray.widgets.length <= 6){
    widgetArray.widgets.forEach((widget) => {
      switch (widget.position) {
        case 1:
          setPosition1({
            ...position1,
            type: widget.type,
            position: widget.position,
          });
          break;
        case 2:
          setPosition2({
            ...position2,
            type: widget.type,
            position: widget.position,
          });
          break;
        case 3:
          setPosition3({
            ...position3,
            type: widget.type,
            position: widget.position,
          });
          break;
        case 4:
          setPosition4({
            ...position4,
            type: widget.type,
            position: widget.position,
          });
          break;
        case 5:
            setPosition5({
              ...position5,
              type: widget.type,
              position: widget.position,
            });
            break;
        case 6:
          setPosition6({
            ...position6,
            type: widget.type,
            position: widget.position,
          });
          break;
        default:
          break;
      }
    });
  }else{
    setPosition1({
      ...position1,
      type: "",
      position: "",
      id: "",
    });
    setPosition2({
      ...position2,
      type: "",
      position: "",
      id: "",
    });
    setPosition3({
      ...position3,
      type: "",
      position: "",
      id: "",
    });
    setPosition4({
      ...position4,
      type: "",
      position: "",
      id: "",
    });
    setPosition5({
      ...position5,
      type: "",
      position: "",
      id: "",
    });
    setPosition6({
      ...position6,
      type: "",
      position: "",
      id: "",
    });
  }
  }, [widgetArray]);

  const posicionar = (valor) => {
    if (valor != null) {
      dispatch({
        type: "posicionar-widget",
        payload: {
          posicion: valor,
          workspace: workspace.id
        }
      });
    }
    return true;
  };

  return (
    <div className="layout">
      <div className="layout6a">
          {position1.position !== "" && position1.position === 1 && position1.type === 1 && 
            <WidgetFondos position={position1}/>
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 2 &&
            <WidgetVcp position={position1}/>
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 3 &&
            <WidgetSuscripciones position={position1}/>
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 4 &&
            <WidgetRescate position={position1}/>
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 5 &&
            <WidgetCartera position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 6 &&
            <WidgetDetalleSuscripciones position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 7 &&
            <WidgetDetalleRescate position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 8 &&
            <WidgetLiquidez position={position1} />
          }
          {position1.position !== "" && position1.position === 1 && position1.type === 9 &&
            <WidgetOperaciones position={position1} />
          }

     

          {position1.position === "" &&
             <div className="widget allingcenter">
             <div className="buttom-layout6a"> 
             <button className="button-create" style={{height: "40px", width: "40px"}} onClick={() =>posicionar("1")}>
               <b>+</b>
             </button>
             </div>
           </div>
          }

        
          {position2.position !== "" && position2.position === 2 && position2.type === 1 && 
            <WidgetFondos position={position2}/>
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 2 &&
            <WidgetVcp position={position2}/>
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 3 &&
            <WidgetSuscripciones position={position2}/>
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 4 &&
            <WidgetRescate position={position2}/>
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 5 &&
            <WidgetCartera position={position2} />
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 6 &&
            <WidgetDetalleSuscripciones position={position2} />
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 7 &&
            <WidgetDetalleRescate position={position2} />
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 8 &&
            <WidgetLiquidez position={position2} />
          }
          {position2.position !== "" && position2.position === 2 && position2.type === 9 &&
            <WidgetOperaciones position={position2} />
          }

          
          {position2.position === "" &&
             <div className="widget allingcenter">
             <div className="buttom-layout6a"> 
             <button className="button-create" style={{height: "40px", width: "40px"}} onClick={() =>posicionar("2")}>
               <b>+</b>
             </button>
             </div>
           </div>
          }

          {position3.position !== "" && position3.position === 3 && position3.type === 1 && 
            <WidgetFondos position={position3}/>
          }
          {position3.position !== "" && position3.position === 3 && position3.type === 2 &&
            <WidgetVcp position={position3}/>
          }
          {position3.position !== "" && position3.position === 3 && position3.type === 3 &&
            <WidgetSuscripciones position={position3}/>
          }
          {position3.position !== "" && position3.position === 3 && position3.type === 4 &&
            <WidgetRescate position={position3}/>
          }
          {position3.position !== "" && position3.position === 3 && position3.type === 5 &&
            <WidgetCartera position={position3} />
          }
          {position3.position !== "" && position3.position === 3 && position3.type === 6 &&
            <WidgetDetalleSuscripciones position={position3} />
          }
          {position3.position !== "" && position3.position === 3 && position3.type === 7 &&
            <WidgetDetalleRescate position={position3} />
          }
          {position3.position !== "" && position3.position === 3 && position3.type === 8 &&
            <WidgetLiquidez position={position3} />
          }
          {position3.position !== "" && position3.position === 3 && position3.type === 9 &&
            <WidgetOperaciones position={position3} />
          }

          
          {position3.position === "" &&
           <div className="widget allingcenter">
           <div className="buttom-layout6a"> 
           <button className="button-create" style={{height: "40px", width: "40px"}} onClick={() =>posicionar("3")}>
             <b>+</b>
           </button>
           </div>
         </div>
          }
        
          {position4.position !== "" && position4.position === 4 && position4.type === 1 && 
            <WidgetFondos position={position4}/>
          }
          {position4.position !== "" && position4.position === 4 && position4.type === 2 &&
            <WidgetVcp position={position4}/>
          }        
          {position4.position !== "" && position4.position === 4 && position4.type === 3 &&
            <WidgetSuscripciones position={position4}/>
          }       
          {position4.position !== "" && position4.position === 4 && position4.type === 4 &&
            <WidgetRescate position={position4}/>
          }
          {position4.position !== "" && position4.position === 4 && position4.type === 5 &&
            <WidgetCartera position={position4} />
          }
          {position4.position !== "" && position4.position === 4 && position4.type === 6 &&
            <WidgetDetalleSuscripciones position={position4} />
          }
          {position4.position !== "" && position4.position === 4 && position4.type === 7 &&
            <WidgetDetalleRescate position={position4} />
          }
          {position4.position !== "" && position4.position === 4 && position4.type === 8 &&
            <WidgetLiquidez position={position4} />
          }
          {position4.position !== "" && position4.position === 4 && position4.type === 9 &&
            <WidgetOperaciones position={position4} />
          }


          
          {position4.position === "" &&
           <div className="widget allingcenter">
           <div className="buttom-layout6a"> 
           <button className="button-create" style={{height: "40px", width: "40px"}} onClick={() =>posicionar("4")}>
             <b>+</b>
           </button>
           </div>
         </div>
          }

          {position5.position !== "" && position5.position === 5 && position5.type === 1 && 
            <WidgetFondos position={position5}/>
          }      
          {position5.position !== "" && position5.position === 5 && position5.type === 2 &&
            <WidgetVcp position={position5}/>
          }       
          {position5.position !== "" && position5.position === 5 && position5.type === 3 &&
            <WidgetSuscripciones position={position5}/>
          }         
          {position5.position !== "" && position5.position === 5 && position5.type === 4 &&
            <WidgetRescate position={position5}/>
          }
          {position5.position !== "" && position5.position === 5 && position5.type === 5 &&
            <WidgetCartera position={position5}/>
          }
          {position5.position !== "" && position5.position === 5 && position5.type === 6 &&
            <WidgetDetalleSuscripciones position={position5} />
          }
          {position5.position !== "" && position5.position === 5 && position5.type === 7 &&
            <WidgetDetalleRescate position={position5} />
          }
          {position5.position !== "" && position5.position === 5 && position5.type === 8 &&
            <WidgetLiquidez position={position5} />
          }
          {position5.position !== "" && position5.position === 5 && position5.type === 9 &&
            <WidgetOperaciones position={position5} />
          }
          
          {position5.position === "" &&
            <div className="widget allingcenter">
            <div className="buttom-layout6a"> 
            <button className="button-create" style={{height: "40px", width: "40px"}} onClick={() =>posicionar("5")}>
              <b>+</b>
            </button>
            </div>
          </div>
          }

          {position6.position !== "" && position6.position === 6 && position6.type === 1 && 
            <WidgetFondos position={position6}/>
          }
          {position6.position !== "" && position6.position === 6 && position6.type === 2 &&
            <WidgetVcp position={position6}/>
          }
          {position6.position !== "" && position6.position === 6 && position6.type === 3 &&
            <WidgetSuscripciones position={position6}/>
          }
          {position6.position !== "" && position6.position === 6 && position6.type === 4 &&
            <WidgetRescate position={position6}/>
          }
          {position6.position !== "" && position6.position === 6 && position6.type === 5 &&
            <WidgetCartera position={position6}/>
          }
          {position6.position !== "" && position6.position === 6 && position6.type === 6 &&
            <WidgetDetalleSuscripciones position={position6} />
          }
          {position6.position !== "" && position6.position === 6 && position6.type === 7 &&
            <WidgetDetalleRescate position={position6} />
          }
          {position6.position !== "" && position6.position === 6 && position6.type === 8 &&
            <WidgetLiquidez position={position6} />
          }
          {position6.position !== "" && position6.position === 6 && position6.type === 9 &&
            <WidgetOperaciones position={position6} />
          }

          
          {position6.position === "" &&
           <div className="widget allingcenter">
            <div className="buttom-layout6a"> 
              <button className="button-create" style={{height: "40px", width: "40px"}} onClick={() =>posicionar("6")}>
                <b>+</b>
              </button>
            </div>
          </div>
          }
      </div>
    </div>
  );
};
export default Layout6A;