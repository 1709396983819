import React from 'react';

import './home.css';



import Layout1A from '../../components/layout/Layout1A';
import Layout2A from '../../components/layout/Layout2A';
import Layout2B from '../../components/layout/Layout2B';
import Layout3A from '../../components/layout/Layout3A';
import Layout3B from '../../components/layout/Layout3B';
import Layout3C from '../../components/layout/Layout3C';
import Layout3D from '../../components/layout/Layout3D';
import Layout3E from '../../components/layout/Layout3E';
import Layout4A from '../../components/layout/Layout4A';
import Layout6A from '../../components/layout/Layout6A';


import { useSelector } from "react-redux";





const Layouts = () => {
    


  

    const workspace = useSelector((state) => state.WorkspaceReducer);
    
   
    
    return (
      
              <div className=''>
                <div className="boxTitle">
                  <p className='workspaceName'>{workspace.name}</p>
                  <span className='workspaceDescription'>{workspace.description}</span>
                  </div>
                                    
              { workspace.layout === "layout-1a" && 
                <Layout1A /> 
              }

              { workspace.layout === "layout-2a" && 
                <Layout2A /> 
              }

              { workspace.layout === "layout-2b" && 
                <Layout2B /> 
              }

              { workspace.layout === "layout-3a" &&
               <Layout3A /> 
              }

              { workspace.layout === "layout-3b" &&
               <Layout3B /> 
              }

              { workspace.layout === "layout-3c" &&
               <Layout3C /> 
              } 

              { workspace.layout === "layout-3d" &&
               <Layout3D /> 
              }   

              { workspace.layout === "layout-3e" &&
               <Layout3E /> 
              }   

              { workspace.layout === "layout-4a" && 
                <Layout4A /> 
              }

              { workspace.layout === "layout-6a" && 
                <Layout6A /> 
              }
                    
              </div>


    )
}
export default Layouts ;
