export default function FilterReducer(state = { filter: JSON }, action) {
    switch (action.type) {
      case "FILTER":
        return {
          ...state,
          filters: action.payload,
        };
  
      default:
        return state;
    }
  }