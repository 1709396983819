import React from "react";
import "../Modals/desanclado-modal.css";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon} from 'react-line-awesome';
import { RiLogoutCircleLine} from "react-icons/ri";

const CerrarSesionModal = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.TokenReducer);
  const cerrarSesion = useSelector((state) => state.CerrarSesionReducer);
 function cerrar() {
    dispatch({
      type: "modal-cerrarsesion",
      payload: "cerrado",
    });
  }

  function logout() {
    
      //axios.post(urlBack + "/workspace/removeAnchor?id="+ desanclado.workspace + "&token=" + token.token)

            dispatch({
              type: "TOKEN",
              payload: "",
            });
    cerrar();
  }
  
  return (
    <div className={cerrarSesion.estado === "abierto" ? "desanclado-modal-overlay" : "d-none"}>
      <div className="desanclado-modal-background">
        <div className='desanclado-modal-container'>
          <div className="desanclado-modal-toolbar">
            <a href="#" className="close-button" onClick={cerrar}>
                <CloseIcon component="span" />    
            </a>
          </div>
          <div className="icon-modal"><RiLogoutCircleLine /></div>
          <p className="desanclado-modal-content">¿Desea cerrar sesión?</p>
          <div className='desanclado-toolbar'>
            <button className="desanclado-button line-grey" onClick={cerrar}>Cancelar</button>
            <button className="desanclado-button btn-grey"onClick={logout}>Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CerrarSesionModal;
