import React from "react";
import "../Modals/desanclado-modal.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import urlBack from "../../utilities/urlBack";
import { AiOutlineExclamationCircle } from "react-icons/ai";

const EliminarWidgetModal = (props) => {


  const dispatch = useDispatch();

  const workspace = useSelector((state) => state.WorkspaceReducer);
  const eliminarwidget = useSelector((state) => state.EliminarWidgetReducer);
 
 function cerrar() {
    dispatch({
      type: "modal-eliminar-widget",
      payload: {
        estado: "cerrado",
        idWidget: "",
      },
    });
  }

  function eliminar() {
    
      axios.post(urlBack + "/workspace/removeWidget?idWorkspace=" + workspace.id + "&idWidget="+ eliminarwidget.id)
      .then((re) => {
        dispatch({
          type: "WIDGETS",
          payload: re.data,
        });
      });
        
    cerrar();
  }

  return (
        <div className={eliminarwidget.estado === "abierto" ? "desanclado-modal-overlay" : "d-none"}>
          <div className="desanclado-modal-background">
            <div className='desanclado-modal-container'>
              <div className="yellow-icon-modal"><AiOutlineExclamationCircle /></div>
              <p className="desanclado-modal-content">¿Desea eliminar el widget?</p>
              <div className='desanclado-toolbar'>
                <button className="desanclado-button line-grey" onClick={cerrar}>
                  No
                </button>
                <button className="desanclado-button btn-grey" onClick={eliminar}>
                  Si
                </button>
              </div>
            </div>
          </div>
        </div>
  );
};
export default EliminarWidgetModal;
