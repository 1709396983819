import React, { useEffect, useState } from "react";
import "./navbar.css";
import Card from "../card/Card";

import { useSelector } from "react-redux";
import axios from "axios";
import urlBack from "../../utilities/urlBack";
import FiltroFondos from "./FiltroFondos";
import MenuCreateWorkspace from "../menu/MenuCreateWorkspace";
import MenuUsuario from "../menu/MenuUsuario";
import MenuEscritorios from "../menu/MenuEscritorios";
import logo from "../../assets/icons/Iconos-barra-superior/logoByma.svg"
import useLocalStorage from 'use-local-storage';
import { BiAdjust } from "react-icons/bi";
import { AiOutlineBell } from "react-icons/ai";

const Navbar = () => {

  const token = useSelector((state) => state.TokenReducer);

  const workspacesArray = useSelector((state) => state.ContenedorWorkspacesReducer);

  const [workspaces, setWorkspaces] = useState([]);
  const [anchors, setAnchors] = useState([]);

  useEffect(() => {
    axios.get(urlBack + "/user/workspaces?token=" + token.token).then((res) => {
      setWorkspaces(res.data);
      setAnchors(res.data.filter(w => w.anchor === true).slice(0,4));
    });
  }, []);

  useEffect(() => {
    setWorkspaces(workspacesArray.workspaces);
    setAnchors(workspacesArray.workspaces.filter(w => w.anchor === true).slice(0,4));
    console.log(workspaces)
}, [workspacesArray]);

const estilo = "cardWrapper.active";
const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
const switchTheme = () => {
  const newTheme = theme === 'light' ? 'dark' : 'light';
  setTheme(newTheme);
  document.body.setAttribute('data-theme', theme);
}
  
  return (
    <div className="navbar">
      <div className="navbarWrapper">
        <div className="navbarLeft">
             <img src={logo} className="navbarLogo" alt="Logo BYMA"></img>
          <div className="navbarCenter">
          {Object.keys(anchors).map((id) => {
           
           const workspace = anchors[id];
              return (
                <>
                  {workspace.anchor === true ? (
                    <Card
                      token={token.token}
                      workspace={workspace}
                      key={workspace.id}
                      className="active"
                    />
                  ) : (
                    ""
                  )}
                </>
              );
            })}

            {/* <MenuCreateWorkspace /> */}
            
            {workspaces.length >= 4 ? <MenuEscritorios cantWorkspaces={workspaces.length} /> : <MenuCreateWorkspace />}
          </div>
        </div>
        <div className="navbarRight">
          <div className="navbarIcons">
            <FiltroFondos />
          </div>
          <div className="navbarIcons modoFondo">
            <button onClick={switchTheme}>
              <BiAdjust component="span" className="iconNav" />
            </button>
          </div>
          <div className="navbarIcons">
            <AiOutlineBell component="span" className="iconNav" />
          </div>
          <div className="navbarIcons" >
            
            <MenuUsuario></MenuUsuario>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
