
import React from 'react';
import { BrowserRouter as Router, Routes, Route, BrowserRouter,  } from "react-router-dom";
import Login from '../components/login/Login2';
import Home from '../pages/home/Home';
import { Provider } from "react-redux";
import { store } from "../store/store";
import Onboarding from '../components/onboarding/Onboarding';

 const MainRoutes = () => {
   
  return (
    <Provider store={store}>
    <BrowserRouter>
      
        <Routes>

        <Route path="/" element={<Login/>} />
        <Route path="/home" element={<Home/>} />
        <Route path="/Onboarding" element={<Onboarding/>} />

        </Routes>
    
    </BrowserRouter>
    </Provider>
    )
};
export default MainRoutes;