import React from 'react'
import './footer.css'

export default function Footer() {
    return (
        <div className='footer'>
            © 2022 Itex | Todos los derechos reservados. 
        </div>
    )
}
