import React, { Fragment } from 'react';
import './App.css';
import { Provider } from "react-redux";
import { store } from "./store/store";
import MainRoutes from "./Routes/routes";

function App () {

  
  return (
    <Fragment>
      <Provider store={store}>
          <MainRoutes/>
       </Provider>
    </Fragment>
  );
}

export default App;
