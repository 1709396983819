import React, { useState } from "react";
import "../menu/menu.css";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AddLayout from '../../assets/icons/Iconos-barra-superior/mas-grey.png'
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import urlBack from "../../utilities/urlBack";
import { CloseIcon } from 'react-line-awesome'
import Layout1A from '../../assets/imagenes/layout-1a.png';
import Layout2A from '../../assets/imagenes/layout-2a.png';
import Layout2B from '../../assets/imagenes/layout-2b.png';
import Layout3A from '../../assets/imagenes/layout-3a.png';
import Layout3B from '../../assets/imagenes/layout-3b.png';
import Layout3C from '../../assets/imagenes/layout-3c.png';
import Layout3D from '../../assets/imagenes/layout-3d.png';
import Layout3E from '../../assets/imagenes/layout-3e.png';
import Layout4A from '../../assets/imagenes/layout-4a.png';
import Layout6A from '../../assets/imagenes/layout-6a.png';

const MenuCreateWorkspace = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

 
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [components, setComponents] = useState(0);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.TokenReducer);
  const [anchor, setAnchor] = useState(false);
  const selectLayout = useSelector((state) => state.SelectLayoutReducer);

  const createWorkspace = () => {
    const layout = {
      description: type,
      components: components,
    };

    axios
      .post(urlBack + "/api/layouts", JSON.stringify(layout), {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res) => {
        const idLayout = res.data._links.layout.href.substr(
          res.data._links.layout.href.lastIndexOf("/") + 1,
          res.data._links.layout.href.length + 1
        );

        axios
          .post(
            urlBack +
              "/workspace/create?name=" +
              name +
              "&description=" +
              description +
              "&idLayout=" +
              idLayout +
              "&token=" +
              token.token +
              "&anchor=" + 
              anchor
          )
          .then((result) => {
            dispatch({
              type: "WORKSPACE",
              payload: {
                id: result.data.id,
                name: result.data.name,
                description: result.data.description,
                layout: result.data.layout.description,
                anchor: result.data.anchor,
              },
            });

            dispatch({
              type: "LAYOUT",
              payload: {
                id: idLayout,
                description: res.data.description,
                components: res.data.components,
              },
            });

            

            axios.get(urlBack + "/user/workspaces?token=" + token.token).then((res) => {
              setOpen(false)
              dispatch({
                type: "WORKSPACES",
                payload: res.data,
              });

              dispatch({
                type: "workspace-activo",
                payload: result.data.id,
              });
            });
          });
      });

      
  };

  const addName = (e) => {
    setName(e.target.value);
  };
  const addDescription = (e) => {
    setDescription(e.target.value);
  };
  function addType(typeOfLayout, cantComponents) {
    let layout;
    
    if(selectLayout.selectLayout !== ""){
          
      layout = document.getElementById(selectLayout.selectLayout);
      layout.className ="layoutListItem";

    
    }

      dispatch({
        type: "SELECTLAYOUT",
        payload: typeOfLayout,
      })
      

    layout = document.getElementById(typeOfLayout)
    layout.className = "layoutListItemSelect";
    setType(typeOfLayout); 
    setComponents(cantComponents);
  }

  const addAnchor = (e) => {
    setAnchor(e.target.checked);
  }

  return (
    <>
      <div 
        id="workspaceBtn" 
        onClick={handleOpen}
        style={{width : '100%'}}>
          {props.cantWorkspaces >= 4 ?  <button className="btnCreateWorkspace">Agregar Nuevo Escritorio</button> : <img src={AddLayout} alt="Layout" />}
         
          </div>

          { open === true && 
          <div className="contMenuEscritorios2"> 
            <div className="menuEscritorios">
              <div className="menuCreateWorkspace" onClose={handleClose}> 
                <div className="menuWrapper">
                  <div className="menuHeader">
                    <div>
                      <span className="title">Nuevo Espacio de Trabajo</span>
                    </div>
                    <div>
                      <CloseIcon className="btnClose" onClick={handleClose}/>
                    </div>
                  </div>
                  <div className="menuContent">
                    <div class="form-control">
                      <label for="workspace-name">Título</label>
                      <input
                        id="workspace-name"
                        type="text"
                        maxLength={18}
                        onChange={addName}
                        className="input"
                      />

                      <label for="workspace-description">Descripción</label>
                      <input
                        id="workspace-description"
                        type="text"
                        maxLength={25}
                        onChange={addDescription}
                        className="input"
                      />

                      <label for="workspace-distribution">Distribución</label>
                      <hr />
                      <span className="small">
                        Seleccione la distribución que tendrán los componentes en el
                        espacio de trabajo.
                      </span>
                      <ul className="layoutList">
                      <li
                          id="layout-1a"
                          className="layoutListItem"
                          onClick={() => addType("layout-1a", 1)}
                        >
                          <img src={Layout1A} className='layout-icon' alt="Layout"/>
                        </li>

                        <li
                          id="layout-2a"
                          className="layoutListItem"
                          onClick={() => addType("layout-2a", 2)}
                        >
                          <img src={Layout2A} className='layout-icon' alt="Layout"/>
                        </li>
                        <li
                          id="layout-2b"
                          className="layoutListItem"
                          onClick={() => addType("layout-2b", 2)}
                        >
                          <img src={Layout2B} className='layout-icon' alt="Layout"/>
                        </li>
                        <li
                          id="layout-3a"
                          className="layoutListItem"
                          onClick={() => addType("layout-3a", 3)}
                        >
                          <img src={Layout3A} className='layout-icon' alt="Layout"/>
                        </li>
                        <li
                          id="layout-3b"
                          className="layoutListItem"
                          onClick={() => addType("layout-3b", 3)}
                        >
                          <img src={Layout3B} className='layout-icon' alt="Layout"/>
                        </li>
                      </ul>
                      <ul className="layoutList">
                        <li
                          id="layout-3c"
                          className="layoutListItem"
                          onClick={() => addType("layout-3c", 3)}
                        >
                          <img src={Layout3C} className='layout-icon' alt="Layout"/>
                        </li>
                        <li
                          id="layout-3d"
                          className="layoutListItem"
                          onClick={() => addType("layout-3d", 3)}
                        >
                          <img src={Layout3D} className='layout-icon' alt="Layout"/>
                        </li>
                        <li
                          id="layout-3e"
                          className="layoutListItem"
                          onClick={() => addType("layout-3e", 3)}
                        >
                          <img src={Layout3E} className='layout-icon' alt="Layout"/>
                        </li>
                        <li
                          id="layout-4a"
                          className="layoutListItem"
                          onClick={() => addType("layout-4a", 4)}
                        >
                          <img src={Layout4A} className='layout-icon' alt="Layout"/>
                        </li>
                        <li
                          id="layout-6a"
                          className="layoutListItem"
                          onClick={() => addType("layout-6a", 6)}
                        >
                          <img src={Layout6A} className='layout-icon' alt="Layout"/>
                        </li>
                      </ul>
                      <label for="my-input">Acceso Directo</label>
                      <hr />
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox  size="medium" onChange={addAnchor} color="warning" />
                          }
                          label="Anclar el espacio de trabajo al inicio."
                          className="small"
                        />
                      </FormGroup>
                      <div className="button">
                        <button className="btnCreateWorkspace" tabIndex="-1" onClick={createWorkspace}>Guardar Espacio</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
    </>
  );
}
export default MenuCreateWorkspace;