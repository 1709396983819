import React, { useEffect } from 'react'
import './card.css'
import { CloseIcon } from 'react-line-awesome'
import { BsFillPinAngleFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux';
import urlBack from '../../utilities/urlBack';
import axios from 'axios';

const CardEscritorio = (props) => {
    
    const dispatch = useDispatch();

    
    const activo = useSelector((state) => state.WorkspaceActivoReducer);
    
    
    const desanclar = () => {
      dispatch({
        type: "abrir-modal-desanclado",
        payload: props.workspace.id,
      });
    }

    const anclar = () => {
      dispatch({
        type: "abrir-modal-anclado",
        payload: props.workspace.id,
      });
    }

    useEffect(() => {
      console.log(props.workspace);
    },[])


    const selectWorkspace = (workspace) => {

        dispatch({
            type: "WORKSPACE",
            payload: {
              id: workspace.id,
              name: workspace.name,
              description: workspace.description,
              layout: workspace.layout.description,
            },
          });
          
          dispatch({
            type: "WorkspaceDesanaclaje",
            payload: props.workspace.id,
          });

          dispatch({
            type: "WorkspaceAnclaje",
            payload: props.workspace.id,
          });
    

        axios.get(urlBack + "/workspace/findWidgets?idWorkspace=" + workspace.id)
          .then((re) => {
            dispatch({
              type: "WIDGETS",
              payload: re.data,
            });
        });

        dispatch({
          type: "workspace-activo",
          payload: workspace.id,
        })
          
    };
    return(
        <div className="cardLarger">
            <div id={props.workspace.id} className={props.workspace.anchor ? "cardLargerWrapperAnclado" : "cardLargerWrapper"}>
                <div className="cardContent" onClick={() => selectWorkspace(props.workspace)}>
                    <span className='cardTitle'>{props.workspace.name}</span>
                    <span className='cardDescription'>{props.workspace.description}</span>
                </div>
                <div className="cardIcons">
                    <span className="wingetAnclado"><BsFillPinAngleFill onClick={desanclar}/></span>
                    <span className="wingetDesanclado"><BsFillPinAngleFill onClick={anclar}/></span>
                    <CloseIcon />
                </div>
            </div>
        </div>
    )
}

export default CardEscritorio;