import React from "react";
import "../Modals/filtro-modal.css";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon} from 'react-line-awesome'

const FiltroModal = (props) => {


  const dispatch = useDispatch();

  const token = useSelector((state) => state.TokenReducer);
  const filtroModal = useSelector((state) => state.FiltroModalReducer);
 
 function cerrar() {
    dispatch({
      type: "cerrar-modal-filtroModal",
      payload: "",
    });
  }

  return (
        <div className={filtroModal.estado === "abierto" ? "desanclado-modal-overlay" : "d-none"}>
          <div className="modalFiltro-modal-background">
            <div className="modalFiltro-modal-header">
              
                <div className="modalFiltro-modal-title">
                    <h3>Filtrar por</h3>
                </div>
                <div className="modalFiltro-modal-toolbar">
                  <p className="close-button" onClick={cerrar}>
                      <CloseIcon component="span" />    
                  </p>
                </div>
            </div>
            <div className="cuerpoFiltro">
              <ul>
                <li>
                  <div>
                    <input type="checkbox" name="" />
                    <span>Fondo</span>
                  </div>
                </li>
                <li>
                  <div>
                    <input type="checkbox" name="" />
                    <span>CNV</span>
                  </div>
                </li>
                <li>
                  <div>
                    <input type="checkbox" name="" />
                    <span>Moneda</span>
                  </div>
                </li>
              </ul>
              <hr />
              <ul>
                <li>
                  <div>
                    <input type="checkbox" name="" />
                    <span>Filtro 1</span>
                  </div>
                </li>
                <li>
                  <div>
                    <input type="checkbox" name="" />
                    <span>Filtro 2</span>
                  </div>
                </li>
                <li>
                  <div>
                    <input type="checkbox" name="" />
                    <span>Filtro 3</span>
                  </div>
                </li>
              </ul>  
            </div>
            <div className="btnFiltroModal">
              <button className="modalFiltro-button">Aplicar</button>
            </div>
          </div>
        </div>
  );
};
export default FiltroModal;
